import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { Revendedor } from 'src/app/model/Revendedor';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import Swal from 'sweetalert2';
import { Produto } from 'src/app/model/Produto';
import { ProdutoService } from 'src/app/service/Produto.service';
import { RevendedorprodutoService } from 'src/app/service/revendedorproduto.service';
import { ReportRequest } from 'src/app/model/ReportRequest';
import { ReportService } from 'src/app/service/report.service';
import { GeneralService } from 'src/app/service/general.service';
import { Ativacao } from 'src/app/model/Ativacao';
import { FabricantedistribuidorService } from 'src/app/service/Fabricantedistribuidor.service';
import { Fabricantedistribuidor } from 'src/app/model/Fabricantedistribuidor';
import { Licenca } from 'src/app/model/Licenca';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.css']
})
export class RelatorioComponent implements OnInit {


  revendedorList: Revendedor[] = [];
  produtoList: Produto[] = [];
  produtoListTemp: Produto[] = [];
  licencasList: Licenca[] = [];
  totalRecords: number = 0;
  relatorio: ReportRequest = new ReportRequest();
  fabricanteDistribuidorList: Fabricantedistribuidor[];
  apiUrl: string = environment.apiUrl;

  constructor(private breadcrumbService: BreadcrumbService,
    private revendedorService: RevendedorService,
    private produtoService: ProdutoService,
    private fabricanteDistribuidorService: FabricantedistribuidorService,
    private revendaProdutoService: RevendedorprodutoService,
    private reportService: ReportService,
    private generalService: GeneralService) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb('Relatórios', 'Relatórios gerenciais');
    this.getRevendedor();
    this.getProduto();
    this.getFabricanteList();
  }

  getFabricanteList() {
    this.fabricanteDistribuidorService.findAll().subscribe(data => {
      this.fabricanteDistribuidorList = data;
    });
  }

  getRevendedor() {
    this.revendedorService.findAll().subscribe(
      success => this.revendedorList = success,
      error => Swal.fire('Erro', 'Erro na busca de revendedores', 'error')
    );
  }

  getProduto() {
    this.produtoService.findAll().subscribe(
      success => {
        this.produtoList = success;
        this.produtoListTemp = success;
      },
      error => Swal.fire('Erro', 'Erro na busca de produtos', 'error')
    );
  }

  getProdutoOferta() {

    if (!this.relatorio.revenda.idrevendedor) {
      this.produtoList = this.produtoListTemp;
      return false;
    }

    this.revendaProdutoService.findByIdRevendedor(this.relatorio.revenda.idrevendedor)
      .subscribe((success: any) => {
        this.produtoList = this.produtoListTemp.filter(prod => success.some(i => i.idproduto === prod.idproduto));
      });
  }

  consultar() {
    this.reportService.findByExample(this.relatorio)
      .subscribe((resposta) => {
        if (resposta.success === true && resposta.data) {
          this.totalRecords = resposta.totalRecords;
          this.licencasList = resposta.data;
        } else {
          Swal.fire('ERRO', 'Nenhum dado encontrado', 'error');
          this.licencasList = [];
        }
      });
  }

  cancelar(licenca: Licenca) {
    this.generalService.cancelar(licenca).subscribe({
      next: (res: any) => {
        if (res.success == false) {
          Swal.fire('Erro', res.message, 'error');
        } else {
          Swal.fire('Sucesso', 'Cancelamento realizado com sucesso', 'success');
        }
      },
      error: (error) => {
        Swal.fire('Erro', error.error.message, 'error');
        console.log(error);
      }
    });
  }

}
