import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Perfil } from 'src/app/model/perfil';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Perfil[]> {
    return this.http.get<Perfil[]>(`${environment.apiUrl}/perfil`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/perfil/${id}`);
  }

  findByIdExample(id: number) { }

  save(perfil: Perfil) {
    return this.http.post(`${environment.apiUrl}/perfil`, perfil);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/perfil/${id}`);
  }
}
