import { Component, OnInit } from '@angular/core';
import { Revendedor } from 'src/app/model/Revendedor';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { RevendedorCytomicService } from 'src/app/service/RevendedorCytomic.service';

@Component({
  selector: 'app-revenda',
  templateUrl: './revenda-cytomic.component.html',
  styleUrls: ['./revenda-cytomic.component.css']
})
export class RevendaCytomicComponent implements OnInit {
  distribuidor: Revendedor = new Revendedor();
  revendedor: Revendedor = new Revendedor();
  revendedorList: Revendedor[];
  constructor(private revendedorCytomicService: RevendedorCytomicService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb('Revendas Cytomic', 'Cadastro de revendedores Cytomic');
    this.listaRevendedor();
  }



  listaRevendedor() {
    this.revendedorCytomicService.findAll().subscribe(data => {
        this.revendedorList = data;
        // console.log(this.revendedorList);
      });
  }

  editar(revendedor: Revendedor) {
    this.revendedor = revendedor;
    const element = document.getElementById('nmrevendedor');
    element.focus();
  }

  excluir(revendedor: Revendedor) {
    this.revendedorCytomicService.delete(revendedor.idrevendedor).subscribe(
      (data: any) => {
        if(!data.message){
          Swal.fire('Sucesso', 'REVENDEDOR EXCLUÍDO COM SUCESSO', 'success');
          this.listaRevendedor();
        }else{
          Swal.fire('Erro', data.message, 'error');
        }
      }
      , error => Swal.fire('Erro', 'ERRO AO EXCLUIR O REVENDEDOR', 'error')
    );
  }

}
