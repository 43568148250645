import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { Pessoa } from 'src/app/model/Pessoa';
import { PessoaService } from 'src/app/service/Pessoa.service';
import { DatatableCustomConfigService } from 'src/app/shared/datatable-custom-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pessoa-listar',
  templateUrl: './pessoa-listar.component.html',
  styleUrls: ['./pessoa-listar.component.css']
})
export class PessoaListarComponent implements OnInit {

  pessoa: Pessoa = new Pessoa();
  pessoaList: Pessoa[] = [];
  dtOptions: DataTables.Settings = new DatatableCustomConfigService().options;
  dtTrigger: Subject<any> = new Subject();
  datatableCustomConfigService = new DatatableCustomConfigService();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  constructor(private pessoaService: PessoaService) { }

  ngOnInit() {
    this.listaPessoa();
    this.dtOptions = this.datatableCustomConfigService.options;
  }

  listaPessoa() {
    this.pessoaService.findAll().pipe(take(1)).subscribe({
      next: (res: any) => {
        this.pessoaList = res;
        console.log(res)
        if(this.dtElement.dtInstance){
          this.dtElement.dtInstance.then(a => a.destroy());
        }
        this.dtTrigger.next(this.dtOptions);
      },
      error: (error: any) => {
            Swal.fire('', 'Erro ao Consultar Pessoa', 'error');
            console.log('erro consulta pessoa', error);
            }
    });
  }

  excluir(pessoa: Pessoa) {
    this.pessoaService.delete(pessoa.idpessoa).pipe(take(1))
    .subscribe({
      next: (data: any) => Swal.fire('', 'REGISTRO EXCLU\u00CDDO COM SUCESSO', 'success').then(success => this.listaPessoa() ),
      error: (error: any) => Swal.fire('', 'ERRO AO EXCLUIR O REGISTRO', 'error')
    });
  }
}
