import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Usuario } from '../model/Usuario';
import { UsuarioService } from '../service/Usuario.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  usuario: Usuario = new Usuario();

  constructor(private usuarioService: UsuarioService,
            private router: Router) { }

  ngOnInit(): void {
  }

  recuperarSenha(){
    this.usuarioService.recovery(this.usuario).pipe(take(1)).subscribe({
      next: (usuarioResponse: Usuario) => {
        Swal.fire('Sucesso', `${usuarioResponse.pessoa.nmpessoa}, encontramos seu cadastro e um e-mail foi enviado para você!`, 'success').then(()=> {
          this.router.navigate(['/', 'login']);
        })
      },
      error: () => Swal.fire('Erro', `Não localizamos seu CPF em nossa base de dados. Verifique novamente!`, 'error'),
      complete: () => null
    });
  }
}
