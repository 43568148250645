import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  usuario: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.usuario = JSON.parse(sessionStorage.getItem('user'));
  }

  redirect(url: string) {
    this.router.navigate(url.split('/'));
  }
}
