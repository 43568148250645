import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Distribuidor } from 'src/app/model/Distribuidor';
import { Fabricante } from 'src/app/model/Fabricante';
import { Fabricantedistribuidor } from 'src/app/model/Fabricantedistribuidor';
import { DistribuidorService } from 'src/app/service/distribuidor.service';
import { FabricanteService } from 'src/app/service/Fabricante.service';
import { FabricantedistribuidorService } from 'src/app/service/Fabricantedistribuidor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fabricante-distribuidor',
  templateUrl: './fabricante-distribuidor.component.html',
  styleUrls: ['./fabricante-distribuidor.component.css']
})
export class FabricanteDistribuidorComponent implements OnInit {

  fabricantedistribuidor: Fabricantedistribuidor = new Fabricantedistribuidor();
  distribuidorList: Array<Distribuidor> = new Array<Distribuidor>();
  iddistribuidorList: Array<number> = [];
  fabricante: Fabricante = new Fabricante();
  fabricanteDistribuidorList: Array<Fabricantedistribuidor> = [];

  constructor(private distribuidorService: DistribuidorService,
    private fabricanteService: FabricanteService,
    private fabricanteDistribuidorService: FabricantedistribuidorService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.route.snapshot.params.idfabricante) {
      this.fabricantedistribuidor.idfabricante = this.route.snapshot.params.idfabricante;

      this.fabricanteService.findById(this.route.snapshot.params.idfabricante).subscribe((fabric: Fabricante) => this.fabricante = fabric);
    }

    this.getVinculados();

  }

  getVinculados() {
    new Promise((resolve, reject) => this.fabricanteDistribuidorService.findAll()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.fabricanteDistribuidorList = res.filter(fabdi => fabdi.idfabricante == this.fabricante.idfabricante);
        resolve(true);
      })
    ).then( () => {
      this.getDistribuidor(this.fabricanteDistribuidorList);
    });
  }

  getDistribuidor(cadastrados: Array<Fabricantedistribuidor>) {
    let listaIds = cadastrados.map(fabDis => fabDis.iddistribuidor);
    this.distribuidorService.findAll().pipe(take(1)).subscribe({
      next: (res: any) => this.distribuidorList = res.filter(dist => listaIds.indexOf(dist.iddistribuidor) < 0)
    })
  }

  gravar() {
    this.fabricanteDistribuidorService.save(this.fabricantedistribuidor)
      .pipe(take(1))
      .subscribe({
        next: (res: any) => res == true ? Swal.fire("Sucesso", "Vinculo realizado com sucesso").then(() => this.getVinculados()) : Swal.fire("Erro", res.message, 'error')
      })
  }
}
