import { Component, OnInit, OnDestroy } from '@angular/core';
import { Ativacao } from 'src/app/model/Ativacao';
import { AtivacaoService } from 'src/app/service/ativacao.service';
import { Pessoa } from 'src/app/model/Pessoa';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css']
})
export class PainelComponent implements OnInit, OnDestroy {

  arrBlocks: Ativacao[];
  arrDetails: Pessoa[];
  interval: any;

  constructor(private ativacaoService: AtivacaoService,
              private breadcrumbService: BreadcrumbService,
              private route: Router) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb('Painel', 'Ativações por produto');
    this.getBlocks();
    // this.interval = setInterval(() => {
    //   this.getBlocks();
    // }, 10000);
  }

  getBlocks() {
    this.ativacaoService.getDash().subscribe((success: any) => {
      this.arrBlocks = success;
    });
  }

  getDetails(idProduto) {
    this.route.navigate(['/', 'main', 'painel','detalhe', idProduto]);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
