import { Pessoa } from './Pessoa';
import { Revendedorproduto } from './Revendedorproduto';
import { Revendedoroferta } from './Revendedoroferta';

export class Revendedor {
    idrevendedor: number;
    iddistribuidor: number;
    idpessoa: number;
    dslogradouro: string;
    nbendereco: string;
    dsbairro: string;
    dscidade: string;
    dscep: string;
    dsuf: string;
    nmrevendedor: string;
    cpfcnpj: string;
    idpais: number;
    dscomplemento: string;
    dsemail: string;
    dtcriacao: Date;
    dtalteracao: Date;
    cdrevendedorapi: string;

    pessoa: Pessoa;
    revendaProduto: Revendedorproduto[];
    revendaOferta: Revendedoroferta[];
}

