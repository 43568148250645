import { Component, OnInit } from '@angular/core';
import { Revendedor } from 'src/app/model/Revendedor';
import Swal from 'sweetalert2';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';

@Component({
  selector: 'app-revenda',
  templateUrl: './revenda.component.html',
  styleUrls: ['./revenda.component.css']
})
export class RevendaComponent implements OnInit {
  distribuidor: Revendedor = new Revendedor();
  revendedor: Revendedor = new Revendedor();
  revendedorList: Revendedor[];
  constructor(private revendedorService: RevendedorService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb('Revendas', 'Cadastro de revendedores');
    this.listaRevendedor();
  }



  listaRevendedor() {
    this.revendedorService.findAll().subscribe(data => {
        this.revendedorList = data;
        // console.log(this.revendedorList);
      });
  }

  editar(revendedor: Revendedor) {
    this.revendedor = revendedor;
    const element = document.getElementById('nmrevendedor');
    element.focus();
  }

  excluir(revendedor: Revendedor) {
    this.revendedorService.delete(revendedor.idrevendedor).subscribe(
      data => {
        Swal.fire('Sucesso', 'REVENDEDOR EXCLUÍDO COM SUCESSO', 'success');
        this.listaRevendedor();
      }
      , error => Swal.fire('Erro', 'ERRO AO EXCLUIR O REVENDEDOR', 'error')
    );
  }

}
