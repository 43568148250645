<div class="row">
    <div class="col-md-12">
  
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <h4 class="box-title">Detalhes sobre as ativações para o produto {{ produto.nmproduto }}.
              </h4>
            </div>
          </div>
        </div>
  
        <!-- /.box-header -->
        <div class="box-body">
          <table id="tblPainelDetail" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Chave</th>
                <th>Cliente</th>
                <th>CPF/CNPJ</th>
                <th>Telefone</th>
                <th>E-mail</th>
                <th>Data Ativação</th>
                <th>Data Expiração</th>
                <th>Ações</th>
            </thead>
            <tbody>
              <tr *ngFor="let ativ of ativacaoList">
                <td><nobr>{{ativ.dsgeneratedkey}}</nobr></td>
                <td>{{ativ.pessoa.nmpessoa}} {{ativ.pessoa.dssobrenome}}</td>
                <td>{{ativ.pessoa.dscpfcnpj}}</td>
                <td>{{ativ.pessoa.dstelefone}}</td>
                <td>{{ativ.pessoa.dsemail}}</td>
                <td>{{ativ.dtinicio | date: 'dd/MM/yyyy'}}</td>
                <td>{{ativ.dtfim | date: 'dd/MM/yyyy'}}</td>
                <td>
                  <!-- <a href="" class="btn btn-sm btn-primary" title="Editar" label="Alterar" [routerLink]="['editar', ofr.idoferta ]"><span
                      class="fa fa-pencil"></span></a>&nbsp; -->
                  <!-- <a (click)="excluir(ofr)" *ngIf="!ofr.dtfim" class="btn btn-sm btn-danger" title="Excluir" label="Ver"><span class="fa fa-trash"></span></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
      </div>
  
    </div>
    <!-- /.box-body -->
  </div>
  
  
  <!-- /.row -->