
<body class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <img src="../../../assets/dist/img/logo-horizontal-color.png" width="250px" class="logo">
      <a><b>Controle</b> Licenças</a>
    </div>
    <!-- /.login-logo -->
    <div class="login-box-body">
      <form #frmLogin="ngForm">
        <p class="login-box-msg">Entre com suas credenciais</p>


        <div class="form-group" [ngClass]="{'has-error': dscpf.invalid && (dscpf.dirty || dscpf.touched)}">
          <input type="text" [(ngModel)]="usuario.dscpf" #dscpf="ngModel" name="dscpf" class="form-control" required placeholder="CPF">
          <span class="help-block" *ngIf="dscpf.invalid && (dscpf.dirty || dscpf.touched)">
            CPF é obrigatório
          </span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': dssenha.invalid && (dssenha.dirty || dssenha.touched)}">
          <input type="password" class="form-control" #dssenha="ngModel" name="dssenha" [(ngModel)]="usuario.dssenha" required placeholder="Senha">
          <span class="help-block" *ngIf="dssenha.invalid && (dssenha.dirty || dssenha.touched)">
            Senha é obrigatória
          </span>
        </div>
        <div class="row">
          <div class="col-8">
            <!--<div class="checkbox icheck">
              <label>
                 <input type="checkbox"> Remember Me 
              </label>
            </div>-->
          </div>
          <!-- /.col -->
          <div class="col-4 float-right mr-1">
            <button type="button" (click)="login()" [disabled]="frmLogin.invalid" class="btn btn-primary">ACESSAR</button>
          </div>
          <div class="col-6 float-left">
            <a [routerLink]="[ '/', 'recuperar-senha' ]">Esqueceu a senha?</a>
          </div>
          <!-- /.col -->
        </div>
      </form>
    </div>
    <!-- /.login-box-body -->
  </div>
  <!-- /.login-box -->

</body>
