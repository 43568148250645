
<body class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <a><b>Controle</b>Licença</a>
      </div>
      <!-- /.login-logo -->
      <div class="login-box-body">
        <form #frmRecuperarSenha="ngForm">
          <p class="login-box-msg">Digite seu CPF para verificação de conta e envio do e-mail de recuperação.</p>
          <div class="form-group" [ngClass]="{'has-error': dscpf.invalid && (dscpf.dirty || dscpf.touched)}">
            <input type="text" [(ngModel)]="usuario.dscpf" #dscpf="ngModel" name="dscpf" class="form-control" required placeholder="CPF">
            <span class="help-block" *ngIf="dscpf.invalid && (dscpf.dirty || dscpf.touched)">
              CPF é obrigatório
            </span>
          </div>
          <div class="row">
            <div class="col-6">
              <button type="button" (click)="recuperarSenha()" [disabled]="frmRecuperarSenha.invalid" class="btn btn-primary float-right">ENVIAR</button>
            </div>
            <div class="col-6">
              <button type="button" [routerLink]="[ '/login' ]" class="btn btn-secondary">VOLTAR</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.login-box-body -->
    </div>
    <!-- /.login-box -->
  
  </body>
  