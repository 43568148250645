import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Oferta } from 'src/app/model/Oferta';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfertaService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Oferta[]> {
    return this.http.get<Oferta[]>(`${environment.apiUrl}/view/oferta`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/oferta/${id}`);
  }

  findByIdExample(id: number) { }

  save(oferta: Oferta) {
    return this.http.post(`${environment.apiUrl}/view/oferta`, oferta);
  }

  update(oferta: Oferta) {
    return this.http.put(`${environment.apiUrl}/view/oferta/`, oferta);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/oferta/${id}`);
  }
}
