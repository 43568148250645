<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Dados do Revendedor</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-3">
            <label>Código</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.idrevendedor" name="idrevendedor"
              #idrevendedor="ngModel" [disabled]="true" placeholder="">
          </div>
          <div class="col-md-3">
            <label>CNPJ/CPF</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.cpfcnpj" name="cpfcnpj" mask="CPF_CNPJ" #cpfcnpj="ngModel">
          </div>
          <div class="col-md-6">
            <label>Razão Social/Nome</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.nmrevendedor" name="nmrevendedor"
              #nmrevendedor="ngModel" placeholder="">
          </div>
        </div>
        
        <div class="row form-group">
          <div class="col-md-6">
            <label>E-mail</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dsemail" name="dsemail" #dsemail="ngModel" placeholder="">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-11">
        <button type="submit" (click)="gravarDados()" class="btn btn-primary pull-right">Cadastrar</button>
      </div>
      <div class="col-md-1">
          <button type="button" [routerLink]="[ '/', 'main', 'revenda' ]" class="btn btn-default">Voltar</button>
      </div>
    </div>
  </div>

</div>