import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { UsuarioService } from 'src/app/service/Usuario.service';
import { Usuario } from 'src/app/model/Usuario';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: Usuario = new Usuario();

  constructor(private usuarioService: UsuarioService,
             private router: Router) { }

  ngOnInit() {

  }

  login() {
    if (this.usuario.dscpf.indexOf('.') > 0) {
      this.usuario.dscpf = this.usuario.dscpf.replace(/\./g, '').replace(/-/g, '');
    }
    this.usuarioService.login(this.usuario).subscribe(
      (response: any) => {
        if(response.success == false){
          Swal.fire('Erro', 'Usuário não encontrado ou senha inválida.', 'error');  
        }else{
          sessionStorage.setItem('user', JSON.stringify(response));
          this.router.navigate(['/main']);
        }
      },
      error => {
        Swal.fire('Erro', 'Usuário não encontrado ou senha inválida.', 'error');
      }
    );
  }

}
