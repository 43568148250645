import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  usuario: any;

  @Output()
  collapsed = new EventEmitter<boolean>();

  collapse = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.usuario = JSON.parse(sessionStorage.getItem('user'));
    // console.log(this.usuario);
  }

  setCollapse() {
    this.collapse = this.collapse ? false : true;
    this.collapsed.emit(this.collapse);
  }

  exit() {
    sessionStorage.removeItem('userCache');
    this.router.navigate(['/login']);
  }

}
