import { Fabricantedistribuidor } from "./Fabricantedistribuidor";

export class Produto {
    idproduto: number;
    cdproduto: string;
    flgerenciado: boolean;
    nmproduto: string;
    dsproduto: string;
    nbncm: number;
    vlcusto: number;
    vlvenda: number;
    vltitulo: number;
    dsimagem: string;
    nbperiodo: number;
    idfabricantedistribuidor: number;
    dtinicio: Date;
    dtfim: Date;
    dslink: string;
    dtcriacao: Date;
    dtalteracao: Date;
    exidproduto: string;

    fabricanteDistribuidor: Fabricantedistribuidor;
}