import { Component, OnInit } from '@angular/core';
import { CustomerProduct } from 'src/app/model/CustomerProduct';
import { Endereco } from 'src/app/model/Endereco';
import { Licenca } from 'src/app/model/Licenca';
import { Lote } from 'src/app/model/Lote';
import { Oferta } from 'src/app/model/Oferta';
import { Produto } from 'src/app/model/Produto';
import { Revendedor } from 'src/app/model/Revendedor';
import { Revendedoroferta } from 'src/app/model/Revendedoroferta';
import { Revendedorproduto } from 'src/app/model/Revendedorproduto';
import { EnderecoService } from 'src/app/service/Endereco.service';
import { LoteService } from 'src/app/service/Lote.service';
import { OfertaService } from 'src/app/service/Oferta.service';
import { ProdutoService } from 'src/app/service/Produto.service';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import { GeneralService } from 'src/app/service/general.service';
import { RevendedorofertaService } from 'src/app/service/revendedoroferta.service';
import { RevendedorprodutoService } from 'src/app/service/revendedorproduto.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class SeguroCyberCadastroComponent implements OnInit {

  customerProduct: CustomerProduct = new CustomerProduct();
  endereco: Endereco;
  lote: Lote = new Lote();
  revendedorList: Revendedor[] = [];
  ofertaList: Oferta[] = [];
  produtoList: Produto[] = [];
  ofertaListTemp: Oferta[] = [];
  produtoListTemp: Produto[] = [];
  revendaProdutoList: Revendedorproduto[] = [];
  revendaOfertaList: Revendedoroferta[] = [];

  constructor(private enderecoService: EnderecoService,
    private revendedorService: RevendedorService,
    private produtoService: ProdutoService,
    private ofertaService: OfertaService,
    private generalService: GeneralService,
    private loteService: LoteService
  ) { }

  ngOnInit() {
    this.getRevendedor();
    this.getProduto();
  }

  getRevendedor() {
    this.revendedorService.findAll().subscribe(
      success => this.revendedorList = success,
      error => Swal.fire('Erro', 'Erro na busca de revendedores', 'error')
    );
  }

  getProduto() {
    this.produtoService.findByFabricanteCode('SEGUROCYBER').subscribe(
      success => this.produtoList = this.produtoListTemp = success,
      error => Swal.fire('Erro', 'Erro na busca de produtos', 'error')
    );
  }

  getOferta() {
    this.ofertaService.findAll().subscribe(
      success => this.ofertaList = this.ofertaListTemp = success,
      error => Swal.fire('Erro', 'Erro na busca de ofertas', 'error')
    );
  }

  gravarDados() {
    this.lote.nbquantidadelicencas = 1;
    this.loteService.gerarLote(this.lote).subscribe(
      (response: any) => {
        if (response.success == false) {
          Swal.fire('Erro', response.message, 'error');
        } else {
          let licenca = (<Licenca[]>response)[0];
          this.customerProduct.cdlicenca = licenca.cdlicenca;
          this.customerProduct.nmpessoa = '';
          this.customerProduct.dtinicioativacao = new Date(this.customerProduct.dtinicioativacao);
          this.customerProduct.dtfimativacao = new Date(this.customerProduct.dtfimativacao);

          this.generalService.ativar(this.customerProduct).subscribe(
            response => {
              if(response.success == false){
                Swal.fire('Erro', JSON.stringify(response.message) , 'error')
              }else{
                Swal.fire({
                  title: 'Sucesso',
                  text: 'Cadastro efetuado com sucesso!\nDeseja emitir o certificado?',
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result: any) => {
                  if (result.isConfirmed) {
                    this.generalService.getCertificado(response.data.dsgeneratedkey);
                  }
                })
              }
            },

            error => Swal.fire('Erro', error.error.message, 'error')
          )
        }
      },
      (error) => {
        console.log(error);

        Swal.fire('Erro', error.error.message, 'error');
      }
    );
  }

  buscaCep() {
    const cepFormatado = this.customerProduct.dscep.substr(0, 5) + '-' + this.customerProduct.dscep.substr(5, 3);
    this.enderecoService.find(cepFormatado).subscribe(
      success => {
        this.endereco = <Endereco>success;
        this.customerProduct.dslogradouro = this.endereco.dsLogradouro;
        this.customerProduct.dscidade = this.endereco.dsCidade;
        this.customerProduct.dsuf = this.endereco.dsUf;
        this.customerProduct.dsbairro = this.endereco.dsBairro;
        this.customerProduct.idpais = 1;
        document.getElementById('nbendereco').focus();
      }
    );
  }
}
