import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { LoteService } from 'src/app/service/Lote.service';
import { Lote } from 'src/app/model/Lote';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import { Revendedor } from 'src/app/model/Revendedor';
import { ProdutoService } from 'src/app/service/Produto.service';
import { Produto } from 'src/app/model/Produto';
import { Oferta } from 'src/app/model/Oferta';
import { OfertaService } from 'src/app/service/Oferta.service';

@Component({
  selector: 'app-consulta-token',
  templateUrl: './consulta-token.component.html',
  styleUrls: ['./consulta-token.component.css']
})
export class ConsultaTokenComponent implements OnInit {
  lote: Lote = new Lote();

  constructor(private route: ActivatedRoute,
    private loteService: LoteService,
    private revendaService: RevendedorService,
    private produtoService: ProdutoService,
    private ofertaService: OfertaService) {


    this.lote.revendedor = new Revendedor();
    this.lote.produto = new Produto();
    this.lote.oferta = new Oferta();

    if (this.route.snapshot.params.idlote) {
      const id = this.route.snapshot.params.idlote;

      this.loteService.findById(id).subscribe(res => {
        this.lote = <Lote>res;

        this.revendaService.findById(this.lote.idrevendedor).subscribe(revenda => {
          this.lote.revendedor = <Revendedor>revenda;
        },
          error => {
            Swal.fire('Erro', 'Erro ao consultar a revenda', 'error');
          }
        );

        if (this.lote.idproduto){
          this.produtoService.findById(this.lote.idproduto).subscribe(produto => {
            this.lote.produto = <Produto>produto;
          },
            error => {
              console.log('erro ao carregar Produto', error);
            }
          );

        } else {
          this.ofertaService.findById(this.lote.idoferta).subscribe(oferta => {
            console.log('oferta', oferta);
            this.lote.oferta = <Oferta>oferta;
            return oferta;
          },
            erroOferta => {
              console.error('erro ao carregar Oferta', erroOferta);

            }
          );
        }

      }
        , error => Swal.fire('Erro', 'Erro ao buscar os dados\n' + error, 'error'));
    }
    console.log(this.lote);
    
  }

  ngOnInit() {

  }


  download() {
    this.loteService.getDownload(this.lote.idlote);
    // Swal.fire('Sucesso', 'era pra ser o download', 'success');
  }

}
