export class Endereco {
    dsTipoLogradouro: string;
    dsCidade: string;
    dsCep: string;
    dsBairro: string;
    dsUf: string;
    idCidade: number;
    idLogradouro: number;
    dsLogradouro: string;
}
