<div class="row">
    <div class="col-md-12">
        <div class="box">
            <form #frmUsuarioRevenda="ngForm" novalidate>
                <div class="box-header with-border">
                    <h3 class="box-title">Cadastrar Usuário do Revendedor</h3>
                </div>
                <!-- /.box-header -->
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error': nmpessoa.invalid && (nmpessoa.dirty || nmpessoa.touched)}">
                                <label>Nome</label>
                                <input type="text" class="form-control" [(ngModel)]="usuario.pessoa.nmpessoa" #nmpessoa="ngModel"
                                    id="nmpessoa" name="nmpessoa" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="nmpessoa.invalid && (nmpessoa.dirty || nmpessoa.touched)">
                                    Nome é obrigatorio
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error':dssobrenome.invalid && (dssobrenome.dirty ||dssobrenome.touched)}">
                                <label>Sobrenome</label>
                                <input type="text" class="form-control" [(ngModel)]="usuario.pessoa.dssobrenome" #dssobrenome="ngModel"
                                    id="dssobrenome" name="dssobrenome" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="dssobrenome.invalid && (dssobrenome.dirty ||dssobrenome.touched)">
                                    Sobrenome é obrigatório
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error':dscpf.invalid && (dscpf.dirty ||dscpf.touched)}">
                                <label>CPF</label>
                                <input type="text" class="form-control" [(ngModel)]="usuario.dscpf" #dscpf="ngModel" id="cpf"
                                    name="cpf" placeholder="" required
                                    mask="000.000.000-00">
                                <span class="help-block" *ngIf="dscpf.invalid && (dscpf.dirty ||dscpf.touched)">
                                    CPF é obrigatorio
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class=" form-group"
                                [ngClass]="{'has-error':dstelefone.invalid && (dstelefone.dirty ||dstelefone.touched)}">
                                <label>Telefone</label>
                                <input type="text" class="form-control" [(ngModel)]="usuario.pessoa.dstelefone" #dstelefone="ngModel"
                                    id="dstelefone" name="dstelefone" placeholder="" required
                                    mask="(00) 00000-0000">
                                <span class="help-block"
                                    *ngIf="dstelefone.invalid && (dstelefone.dirty ||dstelefone.touched)">
                                    Telefone é obrigatorio
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error':dsemail.invalid && (dsemail.dirty ||dsemail.touched)}">
                                <label>E-mail</label>
                                <input type="text" class="form-control" [(ngModel)]="usuario.pessoa.dsemail" #dsemail="ngModel" id="dsemail"
                                    name="dsemail" placeholder="" required>
                                <span class="help-block" *ngIf="dsemail.invalid && (dsemail.dirty ||dsemail.touched)">
                                    E-mail é obrigatorio
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <div class=" form-group"
                                [ngClass]="{'has-error':dssenha.invalid && (dssenha.dirty ||dssenha.touched)}">
                                <label>Senha</label>
                                <input type="password" class="form-control" [(ngModel)]="usuario.dssenha" #dssenha="ngModel"
                                    id="dssenha" name="dssenha" placeholder="" required>
                                <span class="help-block" *ngIf="dssenha.invalid && (dssenha.dirty ||dssenha.touched)">
                                    Senha é obrigatoria
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class=" form-group"
                                [ngClass]="{'has-error':confisenha.invalid && (confisenha.dirty || confisenha.touched)}">
                                <label>Confirmar senha</label>
                                <input type="password" class="form-control" [(ngModel)]="confirmasenha"
                                    #confisenha="ngModel" id="confisenha" name="confisenha" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="confisenha.invalid && (confisenha.dirty || confisenha.touched)">
                                    Confirmar senha é obrigatorio
                                </span>
                                
                                <span class="help-block"
                                    *ngIf="confirmasenha != usuario.dssenha && confisenha.touched">
                                    Senha e confirmação estão diferentes
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.box-body -->
                <div class="box-footer">
                    <div class="row">
                        <div class="col-md-1 col-md-offset-10">
                            <button type="button" class="btn btn-primary pull-right" [disabled]="frmUsuarioRevenda.invalid || confirmasenha != usuario.dssenha"
                                (click)="gravar()">GRAVAR</button>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-default pull-right"
                                [routerLink]="[ '/', 'main', 'revenda','usuarios', idRevenda ]">VOLTAR</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>