<div class="box">
    <div class="box-header text-bold">
        Cadastro de Perfil
    </div>
    <form [formGroup]="frmPerfil" (ngSubmit)="onSubmit()" novalidate>
        <!-- /.box-header -->
        <div class="box-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label for="dsperfil">dsperfil</label>
                    <input type="text" name="dsperfil" id="dsperfil" formControlName="dsperfil" class="form-control"
                        [ngClass]="{'is-invalid': frmPerfil.controls.dsperfil.invalid && (frmPerfil.controls.dsperfil.dirty || frmPerfil.controls.dsperfil.touched)}"
                        required>
                    <div *ngIf="frmPerfil.controls.dsperfil.errors?.['required']  && (frmPerfil.controls.dsperfil.dirty || frmPerfil.controls.dsperfil.touched)"
                        class="text-danger">
                        dsperfil é obrigatório.
                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="flstatus">flstatus</label>
                    <font size="5">
                        <span class="fa" id="flstatus" name="flstatus"
                            (click)="frmPerfil.value.flstatus = !frmPerfil.value.flstatus"
                            [class]="{'fa-toggle-on': frmPerfil.value.flstatus, 'fa-toggle-off': !frmPerfil.value.flstatus}"></span>
                    </font>
                </div>
            </div>
        </div>
        <div class="box-footer" *ngIf="buttons">
            <div class="row">
                <div class="col-6 text-right">
                    <a onclick="window.history.go(-1);" class="btn btn-secondary">VOLTAR</a>
                </div>
                <div class="col-6">
                    <button type="submit" class="btn btn-primary" [disabled]="frmPerfil.invalid">GRAVAR</button>
                </div>
            </div>
        </div>
    </form>
</div>