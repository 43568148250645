import { Component, OnInit } from '@angular/core';
import { TotalRedeemable } from 'src/app/model/TotalRedeemable';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-relatorio-chave-offline',
  templateUrl: './relatorio-chave-offline.component.html',
  styleUrls: ['./relatorio-chave-offline.component.css']
})
export class RelatorioChaveOfflineComponent implements OnInit {

  redeemableList = [];

  constructor(private reportService: ReportService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumb('Relatórios', 'Relatórios de Chaves Offline');
    this.findTotalRedeemable();
  }

  findTotalRedeemable() {
    this.reportService.findTotalsRedeemable().subscribe((res: any) => {
      this.redeemableList = res.data;
    });
  }

}
