import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    breadcrumb = {title: '', description: ''};
    private subject = new Subject<any>();

    constructor() { }

    setBreadcrumb(title: string, description: string) {
        console.log(title, description);
        
        this.breadcrumb.title = title;
        this.breadcrumb.description = description;
        this.subject.next( this.breadcrumb );
    }

    getBreadcrumb(): Observable<any> {
       return this.subject.asObservable();
    }
}
