import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatatableCustomConfigService {

  options: DataTables.Settings = {
    language: {
      info: 'Mostrando _START_ até _END_ de _TOTAL_ registros',
      infoEmpty: 'Mostrando _START_ até _END_ de _TOTAL_ registros',
      emptyTable: 'Nenhum dado encontrado',
      search: 'Pesquisar',
      lengthMenu: 'Mostrar _MENU_ registros',
      paginate: {
        first : 'Primeiro',
        previous: 'Anterior',
        next : 'Próximo',
        last: 'Último'
      }
    }
  };

  constructor() { }
}
