<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar" style="height: auto;">
    <!-- Sidebar user panel -->
    <div class="user-panel">
      <div class="pull-left image">
        <img src="assets/dist/img/user2-160x160.jpg" class="img-circle" alt="User Image">
      </div>
      <div class="pull-left info">
        <p>{{usuario.pessoa.nmpessoa | uppercase}} {{usuario.pessoa.dssobrenome | uppercase}}</p>
        <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>
    
    <!-- sidebar menu: : style can be found in sidebar.less -->
    <ul class="sidebar-menu tree" data-widget="tree">
      <li class="header">MENU PRINCIPAL</li>

      <li *ngFor="let perfilmenu of usuario.perfil.perfilmenus">
        <a href="javascript:void(0);" (click)="redirect(perfilmenu.menu.dslink)">
          <i class="{{perfilmenu.menu.dsiconclass}}"></i> <span>
            {{perfilmenu.menu.dsmenu}}
          </span>
        </a>
      </li>
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>