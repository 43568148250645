import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Lote } from 'src/app/model/Lote';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoteService {

  constructor(private http: HttpClient) { }

  findAll(dias: number): Observable<Lote[]> {
    let diasParam = dias ? `?lastDays=${dias}` : '';
    return this.http.get<Lote[]>(`${environment.apiUrl}/view/lote${diasParam}`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/lote/${id}`);
  }

  findByIdRevenda(idRevenda: number): Observable<Lote[]> {
    return this.http.get<Lote[]>(`${environment.apiUrl}/view/lote/revendedor/${idRevenda}`);
  }

  findByIdExample(id: number) { }

  save(lote: Lote) {
    return this.http.post(`${environment.apiUrl}/view/lote`, lote);
  }

  gerarLote(lote: Lote) {
    return this.http.post(`${environment.apiUrl}/view/lote/gerar-lote`, lote);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/lote/${id}`);
  }

  getDownload(id: number) {
    window.open(`${environment.apiUrl}/view/lote/csv/${id}`);
  }
}
