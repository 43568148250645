import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Menu } from 'src/app/model/menu';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Menu[]> {
    return this.http.get<Menu[]>(`${environment.apiUrl}/menu`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/menu/${id}`);
  }

  findByIdExample(id: number) { }

  save(menu: Menu) {
    return this.http.post(`${environment.apiUrl}/menu`, menu);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/menu/${id}`);
  }
}
