import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportRequest } from '../model/ReportRequest';
import { Ativacao } from '../model/Ativacao';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TotalRedeemable } from '../model/TotalRedeemable';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  findByExample(reportRequest: ReportRequest): Observable<any> {

    return this.http.post(`${environment.apiUrl}/view/report/busca`, reportRequest);

  }

  findTotalsRedeemable(): Observable<TotalRedeemable[]> {

    return this.http.get<TotalRedeemable[]>(`${environment.apiUrl}/view/report/redeemable/totals`);

  }
}
