import { Component, OnInit } from '@angular/core';
import { Revendedor } from 'src/app/model/Revendedor';
import { Pessoa } from 'src/app/model/Pessoa';
import { Produto } from 'src/app/model/Produto';
import { Oferta } from 'src/app/model/Oferta';
import { ProdutoService } from 'src/app/service/Produto.service';
import { OfertaService } from 'src/app/service/Oferta.service';
import { Revendedoroferta } from 'src/app/model/Revendedoroferta';
import { Revendedorproduto } from 'src/app/model/Revendedorproduto';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from 'src/app/model/Usuario';
import { EnderecoService } from 'src/app/service/Endereco.service';
import { Endereco } from 'src/app/model/Endereco';
import { RevendedorCytomicService } from 'src/app/service/RevendedorCytomic.service';

@Component({
  selector: 'app-adicionar-revenda-cytomic',
  templateUrl: './adicionar-revenda-cytomic.component.html',
  styleUrls: ['./adicionar-revenda-cytomic.component.css']
})
export class AdicionarRevendaCytomicComponent implements OnInit {
  produtosList: Produto[];
  produto: Produto = new Produto();
  revendedor: Revendedor = new Revendedor();
  pessoa: Pessoa = new Pessoa();
  produtoList: Produto[] = [];
  ofertaList: Oferta[] = [];
  produtosAdicionados: Produto[] = [];
  ofertasAdicionadas: Oferta[] = [];
  oferta: Oferta = new Oferta();
  endereco: Endereco = new Endereco();
  usuario: Usuario = <Usuario>JSON.parse(sessionStorage.getItem('user'));

  constructor(private produtoService: ProdutoService,
    private ofertaService: OfertaService,
    private revendedorService: RevendedorCytomicService,
    private router: Router,
    private route: ActivatedRoute,
    private enderecoService: EnderecoService) {
      /* this.getProdutoList();
      this.getOfertaList(); */
    }

  ngOnInit() {
    this.getUpdateData();
  }

  getUpdateData() {
    if (this.route.snapshot.params.idrevendedor) {
      this.revendedorService.findById(this.route.snapshot.params.idrevendedor).subscribe(rev => {
        this.revendedor = <Revendedor> rev;
      });
    }
  }

  gravarDados() {
    this.revendedorService.save(this.revendedor).subscribe(
      (response: any) => {
        if(response.success == false){
          Swal.fire('Erro', response.message, 'error')
        }else{
          Swal.fire('Sucesso', 'Dados cadastrados com sucesso!', 'success')
            .then(() => {
              this.router.navigate(['/', 'main', 'revenda-cytomic']);
            });
        }
      },
      error => {
        Swal.fire('Erro', 'Ocorreu um erro na gravação!\n' + JSON.stringify(error), 'error');
      }
    );
  }

  buscaCep() {
    const cepFormatado = this.revendedor.dscep.substring(0, 5) + '-' + this.revendedor.dscep.substring(5, 3);
    this.enderecoService.find(cepFormatado).subscribe(
      success => {
        this.endereco = <Endereco>success;
        this.revendedor.dslogradouro = this.endereco.dsLogradouro;
        this.revendedor.dscidade = this.endereco.dsCidade;
        this.revendedor.dsuf = this.endereco.dsUf;
        this.revendedor.dsbairro = this.endereco.dsBairro;
        this.revendedor.idpais = 1;
        document.getElementById('nbendereco').focus();
      }
    );
  }
}
