<div class="box">
    <div class="box-body">
      <div class="row">
        <div class="col-md-11">
          <h4 class="box-title">Aqui poderá ser realizada a consulta, edição, exclusão e adicionar um novo parceiro.
          </h4>
        </div>
        <div class="col-md-1">
          <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
        </div>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body">
      <table id="usuarioRevendaList" class="table table-bordered table-striped">
        <thead>
          <tr>
            <!-- <th><input type="checkbox"></th> -->
            <th>#</th>
            <th>Nome</th>
            <th>CPF</th>
            <th>Data Cadastro</th>
            <th>Ações</th>
        </thead>
        <tbody>
          <tr *ngFor="let usuario of usuarioRevendedorList">
            <!-- <td><input type="checkbox"></td> -->
            <td>{{usuario.idusuario}}</td>
            <td>{{usuario.pessoa.nmpessoa}} {{usuario.pessoa.dssobrenome}}</td>
            <td>{{usuario.dscpf}}</td>
            <td>{{usuario.dtinicio | date:'dd/MM/yyyy'}}</td>
            <td>
              <nobr>
                <a class="btn btn-sm btn-primary" title="Editar" [routerLink]="[ 'editar', usuario.idrevendedor ]"><span
                    class="fa fa-pencil"></span></a>&nbsp;
                <a class="btn btn-sm btn-danger" title="Inativar" *ngIf="!usuario.dtfim" (click)="inativar(usuario)"><span
                    class="fa fa-trash"></span></a>
                <a class="btn btn-sm btn-success" title="Reativar" *ngIf="usuario.dtfim" (click)="reativar(usuario)"><span
                    class="fa fa-thumbs-up"></span></a>
              </nobr>
            </td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
  </div>
  <!-- /.box-body -->
  
  
  
  <!-- /.row -->