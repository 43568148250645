import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumb: any = {title: '', description: ''};

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.getBreadcrumb().subscribe((breadcrumb: any) => {
      this.breadcrumb = breadcrumb;
    });
  }

}
