<div class="row">
  <form #frmOferta="ngForm" novalidate>
    <div class="col-md-12">
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-md-4">
              <label>Codigo da Oferta</label>
              <input type="text" class="form-control" [disabled]="true" name="idoferta" #idoferta="ngModel"
                [(ngModel)]="oferta.idoferta" placeholder="">
            </div>
            <div class="col-md-8">
              <div class="form-group"
                [ngClass]="{'has-error': nmoferta.invalid && (nmoferta.dirty || nmoferta.touched)}">
                <label>Nome da Oferta</label>
                <input type="text" class="form-control" [(ngModel)]="oferta.nmoferta" name="nmoferta"
                  #nmoferta="ngModel" placeholder="" required>
                <span class="help-block" *ngIf="nmoferta.invalid && (nmoferta.dirty || nmoferta.touched)">
                  Nome da Oferta é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Imagem da Oferta</h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <img class="img-thumbnail" src="{{oferta.dsimagem}}" alt="User profile picture">
              <div class="form-group">
                <label for="dsimagem">Imagem da oferta</label>
                <input type="file" id="dsimagem" name="dsimagem" #dsimagem (change)="getImage()"
                  [(ngModel)]="oferta.dsimagem" accept="image/png, image/jpeg">
                <p class="help-block">Imagem 500px x 500px</p>
              </div>
            </div>
          </div>

          <!-- box-body -->
        </div>
      </div>

      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Selecione dois ou mais produtos</h3>
        </div>
        <div class="box-body">
          <div class="row form-group">
            <div class="col-md-6">
              <label>Fabricante</label>
              <select class="form-control select2" [(ngModel)]="produto.idfabricantedistribuidor"
                name="idfabricantedistribuidor" #idfabricantedistribuidor="ngModel" style="width: 100%;" tabindex="-1"
                aria-hidden="true" (change)="getProdutoList()">
                <option selected="selected">SELECIONE</option>
                <option *ngFor="let fabricante of fabricanteDistribuidorList"
                  value="{{fabricante.idfabricantedistribuidor}}">
                  {{fabricante.fabricante.nmfabricante}}
                </option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Produto</label>
              <select class="form-control select2" [(ngModel)]="produto" name="selProduto" #selProduto="ngModel"
                style="width: 100%;" tabindex="-1" aria-hidden="true">
                <option value="" selected="selected">SELECIONE</option>
                <option *ngFor="let prod of produtosList" [ngValue]="prod">{{prod.nmproduto}}</option>
              </select>
            </div>

            <div class="col-md-2 align-bottom">
              <br>
              <button (click)="adicionarProduto(produto)" class="btn btn-primary pull-right">Adicionar</button>
            </div>
          </div>

          <!-- box-body -->
        </div>
      </div>

      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Produtos selecionados para oferta</h3>
        </div>
        <div class="box-body">

          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <!-- <th><input type="checkbox"></th> -->
                <th>Cód do Produto</th>
                <th>Descrição</th>
                <th>NPM</th>
                <th>NCM</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let prodAd of produtosAdicionados">
                <!-- <td><input type="checkbox"></td> -->
                <td>{{prodAd.idproduto}}</td>
                <td>{{prodAd.nmproduto}}</td>
                <td>{{prodAd.cdproduto}}</td>
                <td>{{prodAd.nbncm}}</td>
                <td>
                  <button (click)="removerProduto(prodAd)" class="btn btn-sm btn-danger" title="Excluir" label="Ver">
                    <span class="fa fa-trash"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Dados Gerais da Oferta</h3>
        </div>
        <div class="box-body">
          <div class="col-md-4">
            <label>Preço de Custo</label>
            <input type="text" class="form-control" [(ngModel)]="oferta.vlcusto" name="vlcusto" #vlcusto="ngModel"
              currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="">
          </div>
          <div class="col-md-4">
            <label>Preço de Venda</label>
            <input type="text" class="form-control" [(ngModel)]="oferta.vlvenda" currencyMask name="vlvenda"
              #vlvenda="ngModel" [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="">
          </div>
          <div class="col-md-4">
            <label>Duração</label>
            <select class="form-control select2" [(ngModel)]="oferta.nbperiodo" style="width: 100%;" tabindex="-1"
              name="nbperiodo" #nbperiodo="ngModel" aria-hidden="true">
              <option value="1" selected="selected">1 mês</option>
              <option value="3">3 meses</option>
              <option value="6">6 meses</option>
              <option value="12">12 meses</option>
              <option value="24">24 meses</option>
              <option value="36">36 meses</option>
            </select>
          </div>
        </div>
      </div>

      <div class="box">

        <div class="box-header with-border">
          <h3 class="box-title">PagSeguro</h3>&nbsp;
          <small>Aqui você pode informar se esta oferta poderá ser paga através do PagSeguro</small>
        </div>

        <div class="box-body">

          <div class="row">
            <div class="col-md-4">
              <label>O pagamento será recorrente? </label> &nbsp;
              <input type="radio" [(ngModel)]="oferta.flpagseguro" name="flpagseguro" #flpagseguro="ngModel"
                [value]="true"> Sim
              <input type="radio" [(ngModel)]="oferta.flpagseguro" name="flpagseguro" #flpagseguro="ngModel"
                [value]="false"> Não
            </div>
          </div>

          <div *ngIf="oferta.flpagseguro">
            <div class="col-md-12">
              <div class="alert alert-info alert-dismissible">
                <h4><i class="icon fa fa-info"></i>PAGAMENTO RECORRENTE</h4>
                O pagamento recorrente atende aos casos onde a assinatura será automatizada
                via cartão de crédito. Esta modalidade não oferece parcelamento do valor e o
                preenchimento dos campos abaixo são obrigatórios.
              </div>
            </div>
            <div class="col-md-3">
              <label>Taxa de adesão</label>
              <input name="vlprimeiropagto" #vlprimeiropagto class="form-control" [(ngModel)]="oferta.vlprimeiropagto" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="" type="text" style="text-align: right;">
            </div>
            <div class="col-md-3">
              <label>Valor recorrente</label>
              <input name="vlrecorrente" class="form-control" [(ngModel)]="oferta.vlrecorrente" #vlrecorrente currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="" type="text" style="text-align: right;">
            </div>
            <div class="col-md-3">
              <label>Parar de cobrar em</label>
              <select aria-hidden="true" class="form-control select2" [(ngModel)]="oferta.nbperiodorecorrencia" name="nbperiodorecorrencia">
                <option selected="selected" value="1">1 mês</option>
                <option value="3">3 meses</option>
                <option value="6">6 meses</option>
                <option value="12">12 meses</option>
              </select>
            </div>
            <div class="col-md-3">
              <label>Período de teste</label>
              <select aria-hidden="true" class="form-control select2" [(ngModel)]="oferta.nbiniciocobranca" name="nbiniciocobranca"
                style="width: 100%;" tabindex="-1">
                <option selected="selected" value="0">Sem teste</option>
                <option value="1">1 mês</option>
                <option value="3">3 meses</option>
                <option value="6">6 meses</option>
                <option value="12">12 meses</option>
              </select>
            </div>
          </div>

          <div *ngIf="!oferta.flpagseguro">
            <div class="col-md-12">
              <div class="alert alert-info alert-dismissible">
                <h4><i class="icon fa fa-info"></i>PAGAMENTO ÚNICO</h4>
                O pagamento único é o modelo mais comum onde o usuário pode contratar e parcelar o
                valor total. Para isso escolha abaixo a quantidade de parcelas sem juros e, se houver
                desconto, qual será o valor total cobrado do usuário.
              </div>
            </div>
            <div class="col-md-6">
              <label>Preço de Venda Anual com Desconto</label>
              <input class="form-control" name="vlcustoanual" [(ngModel)]="oferta.vlcustoanual" #vlcustoanual currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="" type="text" style="text-align: right;">
            </div>
            <div class="col-md-6">
              <label>Vendedor assume a taxa de juros até a</label>
              <select aria-hidden="true" class="form-control select2" [(ngModel)]="oferta.nbperiodosemjuros" name="nbperiodosemjuros">
                <option selected="selected" value="1">2ª parcela</option>
                <option value="3">3ª parcela</option>
                <option value="6">4ª parcela</option>
                <option value="12">5ª parcela</option>
              </select>
            </div>
          </div>

        </div>
      </div>

      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Landing Page</h3>&nbsp;<small>Aqui você pode informar os dados do projeto criado para a
            landing desta oferta</small>
        </div>
        <div class="box-body">
          <div class="col-md-4">
            <label>Nome do projeto para landing:</label> &nbsp;
            <input type="text" class="form-control" name="dsprojetolanding" #dsprojetolanding="ngModel"
              [(ngModel)]="oferta.dsprojetolanding" maxlength="100">
          </div>
          <div class="col-md-4">
            <label>URL de ativação:</label> &nbsp;
            <input type="text" class="form-control" name="dslinkativacao" #dslinkativacao="ngModel"
              [(ngModel)]="oferta.dslinkativacao" maxlength="100">
          </div>
          <div class="col-md-4">
            <label>URL para landing:</label> &nbsp;
            <input type="text" class="form-control" name="dslinklanding" #dslinklanding="ngModel"
              [(ngModel)]="oferta.dslinklanding" maxlength="100">
          </div>
          <div class="col-md-12">
            <label>Acesso ao construtor de landing</label> &nbsp;
            <a href="http://builder.virtucomm.com.br/" target="_blank">IR PARA O CONSTRUTOR</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <a type="a" [routerLink]="['../']" class="btn btn-primary pull-right">Voltar</a>
        </div>
        <div class="row">
          <div class="col-md-2">
            <button (click)="gravarDados()" type="button" [disabled]="frmOferta.invalid"
              class="btn btn-primary pull-right">Cadastrar</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>