<div class="row">
    <div class="col-md-12">
        <div class="box">
            <form #frmUsuarioCytomic="ngForm" novalidate>
                <div class="box-header with-border">
                    <h3 class="box-title">Cadastrar Usuário do Revendedor</h3>
                </div>
                <!-- /.box-header -->
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error': dsnome.invalid && (dsnome.dirty || dsnome.touched)}">
                                <label>Nome</label>
                                <input type="text" class="form-control" [(ngModel)]="usuarioCytomic.dsnome" #dsnome="ngModel"
                                    id="dsnome" name="dsnome" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="dsnome.invalid && (dsnome.dirty || dsnome.touched)">
                                    Nome é obrigatório
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error': dssobrenome.invalid && (dssobrenome.dirty || dssobrenome.touched)}">
                                <label>Sobrenome</label>
                                <input type="text" class="form-control" [(ngModel)]="usuarioCytomic.dssobrenome" #dssobrenome="ngModel"
                                    id="dssobrenome" name="dssobrenome" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="dssobrenome.invalid && (dssobrenome.dirty || dssobrenome.touched)">
                                    Sobrenome é obrigatório
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error':cpfcnpj.invalid && (cpfcnpj.dirty ||cpfcnpj.touched)}">
                                <label>CPF/CNPJ</label>
                                <input type="text" class="form-control" [(ngModel)]="usuarioCytomic.dscpfcnpj" #cpfcnpj="ngModel" id="cpf"
                                    name="cpf" placeholder="" required
                                    mask="CPF_CNPJ">
                                <span class="help-block" *ngIf="cpfcnpj.invalid && (cpfcnpj.dirty ||cpfcnpj.touched)">
                                    CPF/CNPJ é obrigatório
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group"
                                [ngClass]="{'has-error':dsemail.invalid && (dsemail.dirty ||dsemail.touched)}">
                                <label>E-mail</label>
                                <input type="text" class="form-control" [(ngModel)]="usuarioCytomic.dsemail" #dsemail="ngModel" id="dsemail"
                                    name="dsemail" placeholder="" required>
                                <span class="help-block" *ngIf="dsemail.invalid && (dsemail.dirty ||dsemail.touched)">
                                    E-mail é obrigatório
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            
                        </div>
                    </div>


                    <!-- <div class="row">
                        <div class="col-md-6">
                            <div class=" form-group"
                                [ngClass]="{'has-error':dssenha.invalid && (dssenha.dirty ||dssenha.touched)}">
                                <label>Senha</label>
                                <input type="password" class="form-control" [(ngModel)]="usuarioCytomic.dssenha" #dssenha="ngModel"
                                    id="dssenha" name="dssenha" placeholder="" required>
                                <span class="help-block" *ngIf="dssenha.invalid && (dssenha.dirty ||dssenha.touched)">
                                    Senha é obrigatoria
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class=" form-group"
                                [ngClass]="{'has-error':confisenha.invalid && (confisenha.dirty || confisenha.touched)}">
                                <label>Confirmar senha</label>
                                <input type="password" class="form-control" [(ngModel)]="confirmasenha"
                                    #confisenha="ngModel" id="confisenha" name="confisenha" placeholder="" required>
                                <span class="help-block"
                                    *ngIf="confisenha.invalid && (confisenha.dirty || confisenha.touched)">
                                    Confirmar senha é obrigatório
                                </span>
                                
                                <span class="help-block"
                                    *ngIf="confirmasenha != usuarioCytomic.dssenha && confisenha.touched">
                                    Senha e confirmação estão diferentes
                                </span>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- /.box-body -->
                <div class="box-footer">
                    <div class="row">
                        <div class="col-md-1 col-md-offset-10">
                            <button type="button" class="btn btn-primary pull-right" [disabled]="frmUsuarioCytomic.invalid"
                                (click)="gravar()">GRAVAR</button>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-default pull-right"
                                [routerLink]="[ '/', 'main', 'revenda','usuarios', idRevenda ]">VOLTAR</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>