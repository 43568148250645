export class UsuarioCytomic {
    idusuario: number;
	dsnome: string;
	dssobrenome: string;
	dscpfcnpj: string;
	dsemail: string;
	accountidcytomic: string;
	dtfim: Date;
	dtinicio: Date;
	idrevendedor: number;
	dtcriacao: Date;
	dtalteracao: Date;
}

