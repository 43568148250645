<body class="skin-blue sidebar-mini" [ngClass]="{'sidebar-collapse': collapse, 'sidebar-open': collapse}">
    <div class="wrapper">

        <app-header (collapsed)="getCollapse($event)"></app-header>

        <app-sidebar></app-sidebar>

        <div class="content-wrapper" style="min-height: 916px;">
            <app-breadcrumb></app-breadcrumb>

            <section class="content">
                <router-outlet></router-outlet>
            </section>
        </div>

        <app-footer></app-footer>
    </div>
</body>