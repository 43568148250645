export class Revendedorproduto {
    idrevendedorproduto: number;
    idrevendedor: number;
    idproduto: number;
    flstatus: boolean;
    dtinicio: Date;
    dtfim: Date;
    dtcriacao: Date;
    dtalteracao: Date;
}

