<div class="col-md-12" *ngIf="redeemableList.length > 0">
    <div class="box">
      <div class="box-header">
        <div class="box-title">
          <label> Resultados (Total de registros: {{redeemableList.length}}) </label>
        </div>
      </div>
      <div class="box-body">

        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Total Chaves</th>
              <th>Ativas</th>
              <th>Disponíveis</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let redeem of redeemableList">
              <td>{{ redeem.dssku }}</td>
              <td>{{ redeem.total }}</td>
              <td>{{ redeem.ativos }}</td>
              <td>{{ redeem.inativos }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- /.box-body -->
    </div>
    <!-- /.row -->
    <!-- <div class="row">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary pull-right" [routerLink]="[ '/', 'main', 'token' ]">Voltar</button>
      </div>
    </div> -->
  </div>