import { Pessoa } from './Pessoa';

export class Usuario {
    idusuario: number;
    iddistribuidor: number;
    idpessoa: number;
    idperfil: number;
    idrevendedor: number;
    dscpf: string;
    dssenha: string;
    dtinicio: Date;
    dtfim: Date;
    dtcriacao: Date;
    dtalteracao: Date;
    dsrecoverykey: string;
    dsconfirmacao: string;

    pessoa: Pessoa = new Pessoa();
}
