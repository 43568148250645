import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {


  collapse = false;

  constructor() { }

  ngOnInit() {
  }

  getCollapse(retorno: boolean) {
    this.collapse = retorno;
  }

}
