import { OfertaProduto } from './OfertaProduto';

export class Oferta {
    idoferta: number;
    iddistribuidor: number;
    nmoferta: string;
    dtinicio: Date;
    dtfim: Date;
    vlcusto: number;
    vlvenda: number;
    nbperiodo: number;
    flpagseguro: boolean;
    dspagseguroid: string;
    dsprojetolanding: string;
    dsimagem: string;
    vlprimeiropagto: number;
    vlrecorrente: number;
    nbperiodorecorrencia: number;
    nbiniciocobranca: number;
    nbperiodosemjuros: number;
    dtcriacao: Date;
    dtalteracao: Date;
    dslinkativacao: string;
    dslinklanding: string;

    base64: string;

    ofertaProduto: OfertaProduto[];
}

