export class Pessoa {
    idpessoa: number;
    nmpessoa: string;
    dssobrenome: string;
    dslogradouro: string;
    nbendereco: string;
    dsbairro: string;
    dscidade: string;
    dscep: string;
    dsuf: string;
    dsemail: string;
    dstelefone: string;
    dsobservacao: string;
    idpais: number;
    dsramal: string;
    dstelefonecomercial: string;
    dtcriacao: Date;
    dtalteracao: Date;
    dscpfcnpj: string;
    dtnascimento: Date;
    dscomplemento: string;
    cdparceiro: string;
}

