import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/Usuario.service';
import { Usuario } from 'src/app/model/Usuario';
import Swal from 'sweetalert2';
import { RevendedorCytomicService } from 'src/app/service/RevendedorCytomic.service';
import { Revendedor } from 'src/app/model/Revendedor';
import { UsuarioCytomic } from 'src/app/model/UsuarioCytomic';

@Component({
  selector: 'app-usuario-revenda-cytomic-cadastro',
  templateUrl: './usuario-revenda-cytomic-cadastro.component.html',
  styleUrls: ['./usuario-revenda-cytomic-cadastro.component.css']
})
export class UsuarioRevendaCytomicCadastroComponent implements OnInit {

  usuarioCytomic: UsuarioCytomic = new UsuarioCytomic();
  confirmasenha = '';
  idRevenda: number;

  cpf = '0{3}.0{3}.0{3}-0{2} ';

  telefoneMask() {
    return '(9{2}) 9{5}-9{4}';
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private revendedorCytomicService: RevendedorCytomicService,
    private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.usuarioCytomic.idrevendedor = this.idRevenda = this.route.snapshot.params.idrevendedor;
  }

  gravar() {
    this.revendedorCytomicService.saveUser(this.usuarioCytomic).subscribe(
      (res: any) => {
        if (res.success === true) {
          Swal.fire('Sucesso', 'Dados gravados com sucesso.', 'success')
            .then(() => {
              this.router.navigate([res.data.idusuario]);
            });
        } else {
          Swal.fire('Erro', res.message, 'error');
        }
      },
      (erro) => {
        Swal.fire('Erro', 'Erro ao tentar gravar os dados', 'error');
      });
  }
}
