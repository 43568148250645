import { Component, OnInit } from '@angular/core';
import { Produto } from 'src/app/model/Produto';
import { AtivacaoService } from 'src/app/service/ativacao.service';
import { Ativacao } from 'src/app/model/Ativacao';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-painel-detail',
  templateUrl: './painel-detail.component.html',
  styleUrls: ['./painel-detail.component.css']
})
export class PainelDetailComponent implements OnInit {

  produto: Produto = new Produto();
  ativacaoList: Array<Ativacao> = [];

  constructor(private ativacaoService: AtivacaoService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.ativacaoService.getDetails(this.route.snapshot.params.idproduto).subscribe(res => this.ativacaoList = res);
  }

}
