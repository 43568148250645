import { Produto } from './Produto';
import { Revendedor } from './Revendedor';
import { Fabricante } from './Fabricante';

export class ReportRequest {
   produto: Produto = new Produto();
   revenda: Revendedor = new Revendedor();
   fabricante: Fabricante = new Fabricante();
   dtInicio: Date;
   dtFim: Date;
   chave: string;
   situacao: Array<string> = [];
}
