import { Component, OnInit } from '@angular/core';
import { Oferta } from 'src/app/model/Oferta';
import { OfertaService } from 'src/app/service/Oferta.service';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';

@Component({
  selector: 'app-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: ['./oferta.component.css']
})
export class OfertaComponent implements OnInit {
    distribuidor: Oferta = new Oferta();
    oferta: Oferta = new Oferta();
    ofertaList: Oferta[];
    todos: any = false;

    constructor(private ofertaService: OfertaService,
                private breadcrumbService: BreadcrumbService) { }


    ngOnInit() {
      this.breadcrumbService.setBreadcrumb('Oferta', 'Cadastro de ofertas');
      this.listaOferta();
    }

    listaOferta() {
      this.ofertaService.findAll().subscribe(data => {
          this.ofertaList = data;
        });
    }
    editar(oferta: Oferta) {
      this.oferta = oferta;
      const element = document.getElementById('nmoferta');
      element.focus();
    }
    excluir(oferta: Oferta) {
      oferta.dtfim = new Date();

      this.ofertaService.update(oferta).subscribe(
        data => {
          Swal.fire('Sucesso', 'OFERTA EXCLUÍDA COM SUCESSO', 'success');
          this.listaOferta();
        }
        , error => Swal.fire(':-(', 'ERRO AO EXCLUIR A OFERTA', 'error')
      );
    }
}
