import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './common/login/login.component';
import { PainelComponent } from './custom/painel/painel.component';
import { ParceiroComponent } from './custom/parceiro/parceiro.component';
import { ProdutoComponent } from './custom/produto/produto.component';
import { RevendaComponent } from './custom/revenda/revenda.component';
import { RelatorioComponent } from './custom/relatorio/relatorio.component';
import { FabricanteComponent } from './custom/fabricante/fabricante.component';
import { OfertaComponent } from './custom/oferta/oferta.component';
import { AdicionarRevendaComponent } from './custom/revenda/adicionar-revenda/adicionar-revenda.component';
import { AdicionarProdutoComponent } from './custom/produto/adicionar-produto/adicionar-produto.component';
import { TokenComponent } from './custom/token/token.component';
import { AdicionarTokenComponent } from './custom/token/adicionar-token/adicionar-token.component';
import { ConsultaTokenComponent } from './custom/token/consulta-token/consulta-token.component';
import { AdicionarOfertaComponent } from './custom/oferta/adicionar-oferta/adicionar-oferta.component';
import { AuthGuard } from './_auth/auth.guard';
import { MainComponent } from './common/main/main.component';
import { PainelDetailComponent } from './custom/painel/painel-detail/painel-detail.component';
import { UsuarioRevendaComponent } from './custom/revenda/usuario-revenda/usuario-revenda.component';
import { UsuarioRevendaCadastroComponent } from './custom/revenda/usuario-revenda/usuario-revenda-cadastro/usuario-revenda-cadastro.component';
import { LinkPagamentoComponent } from './custom/link-pagamento/link-pagamento.component';
import { RelatorioChaveOfflineComponent } from './custom/relatorio-chave-offline/relatorio-chave-offline.component';
import { RevendaCytomicComponent } from './custom/revenda-cytomic/revenda-cytomic.component';
import { AdicionarRevendaCytomicComponent } from './custom/revenda-cytomic/adicionar-revenda/adicionar-revenda-cytomic.component';
import { UsuarioRevendaCytomicComponent } from './custom/revenda-cytomic/usuario-revenda/usuario-revenda-cytomic.component';
import { UsuarioRevendaCytomicCadastroComponent } from './custom/revenda-cytomic/usuario-revenda/usuario-revenda-cadastro/usuario-revenda-cytomic-cadastro.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './nova-senha/nova-senha.component';
import { FabricanteDistribuidorComponent } from './custom/fabricante-distribuidor/fabricante-distribuidor.component';
import { SeguroCyberCadastroComponent } from './custom/seguro-cyber/cadastro/cadastro.component';
import { PessoaListarComponent } from './custom/pessoa/listar/pessoa-listar.component';
import { PessoaCadastroComponent } from './custom/pessoa/cadastrar/pessoa-cadastro.component';
import { PerfilListarComponent } from './custom/perfil/listar/perfil-listar.component';
import { PerfilCadastroComponent } from './custom/perfil/cadastrar/perfil-cadastro.component';
import { MenuListarComponent } from './custom/menu/listar/menu-listar.component';
import { MenuCadastroComponent } from './custom/menu/cadastrar/menu-cadastro.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'recuperar-senha', component: RecuperarSenhaComponent},
  { path: 'nova-senha/:dsrecoverykey', component: NovaSenhaComponent},
  {
    path: 'main', component: MainComponent, canActivate: [AuthGuard], children: [
      {path: '', redirectTo: 'painel', pathMatch: 'full' },
      { path: 'painel', children: [
        {path: '', component: PainelComponent , canActivate: [AuthGuard] },
        { path: 'detalhe/:idproduto', component: PainelDetailComponent , canActivate: [AuthGuard]}
      ]},
      { path: 'parceiro', component: ParceiroComponent , canActivate: [AuthGuard]},
      {
        path: 'revenda', children: [
          { path: '', component: RevendaComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: AdicionarRevendaComponent , canActivate: [AuthGuard]},
          { path: 'editar/:idrevendedor', component: AdicionarRevendaComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor', component: UsuarioRevendaComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor/editar/:idusuario', component: UsuarioRevendaCadastroComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor/adicionar', component: UsuarioRevendaCadastroComponent , canActivate: [AuthGuard]}
        ]
      },
      {
        path: 'revenda-cytomic', children: [
          { path: '', component: RevendaCytomicComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: AdicionarRevendaCytomicComponent , canActivate: [AuthGuard]},
          { path: 'editar/:idrevendedor', component: AdicionarRevendaCytomicComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor', component: UsuarioRevendaCytomicComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor/editar/:idusuario', component: UsuarioRevendaCytomicCadastroComponent , canActivate: [AuthGuard]},
          { path: 'usuarios/:idrevendedor/adicionar', component: UsuarioRevendaCytomicCadastroComponent , canActivate: [AuthGuard]}
        ]
      },
      { path: 'relatorio', component: RelatorioComponent , canActivate: [AuthGuard]},
      { path: 'chave-offline', component: RelatorioChaveOfflineComponent , canActivate: [AuthGuard]},
      {
        path: 'oferta', children: [
          { path: '', component: OfertaComponent , canActivate: [AuthGuard]},
          { path: 'adicionar', component: AdicionarOfertaComponent , canActivate: [AuthGuard]},
          { path: 'editar/:idoferta', component: AdicionarOfertaComponent , canActivate: [AuthGuard]}
        ]
      },
      { path: 'fabricante', component: FabricanteComponent , canActivate: [AuthGuard]},
      { path: 'fabricante-distribuidor/:idfabricante', component: FabricanteDistribuidorComponent , canActivate: [AuthGuard]},
      {
        path: 'produto', children: [
          { path: '', component: ProdutoComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: AdicionarProdutoComponent, canActivate: [AuthGuard] },
          { path: 'editar/:idproduto', component: AdicionarProdutoComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'token', children: [
          { path: '', component: TokenComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: AdicionarTokenComponent, canActivate: [AuthGuard] },
          { path: 'consulta/:idlote', component: ConsultaTokenComponent, canActivate: [AuthGuard] },
          { path: 'revenda/:idrevendedor', component: UsuarioRevendaCytomicComponent , canActivate: [AuthGuard]},
        ]
      },
      {
        path: 'seguro-cyber', children: [
          { path: '', redirectTo: 'cadastro', pathMatch: 'full'},
          { path: 'cadastro', component: SeguroCyberCadastroComponent, canActivate: [AuthGuard] },
        ]
      },
      {
        path: 'pessoa', children: [
          { path: '', component: PessoaListarComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: PessoaCadastroComponent, canActivate: [AuthGuard] },
          { path: 'editar/:idpessoa', component: PessoaCadastroComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'perfil', children: [
          { path: '', component: PerfilListarComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: PerfilCadastroComponent, canActivate: [AuthGuard] },
          { path: 'editar/:idperfil', component: PerfilCadastroComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'menu', children: [
          { path: '', component: MenuListarComponent, canActivate: [AuthGuard] },
          { path: 'adicionar', component: MenuCadastroComponent, canActivate: [AuthGuard] },
          { path: 'editar/:idmenu', component: MenuCadastroComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'link-pagamento', children: [
          { path: '', component: LinkPagamentoComponent, canActivate: [AuthGuard] }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
