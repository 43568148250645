import { Component, OnInit } from '@angular/core';
import { Oferta } from 'src/app/model/Oferta';
import { Produto } from 'src/app/model/Produto';
import { ProdutoService } from 'src/app/service/Produto.service';
import { Fabricantedistribuidor } from 'src/app/model/Fabricantedistribuidor';
import { FabricantedistribuidorService } from 'src/app/service/Fabricantedistribuidor.service';
import Swal from 'sweetalert2';
import { OfertaProduto } from 'src/app/model/OfertaProduto';
import { ActivatedRoute, Router } from '@angular/router';
import { OfertaService } from 'src/app/service/Oferta.service';

@Component({
  selector: 'app-adicionar-oferta',
  templateUrl: './adicionar-oferta.component.html',
  styleUrls: ['./adicionar-oferta.component.css']
})
export class AdicionarOfertaComponent implements OnInit {

  oferta: Oferta = new Oferta();
  produtosList: Produto[];
  produto: Produto = new Produto();
  fabricanteDistribuidorList: Fabricantedistribuidor[];
  produtosAdicionados: Produto[] = [];
  ofertaProdutoList: OfertaProduto[];

  constructor(private produtoService: ProdutoService,
    private fabricanteDistribuidorService: FabricantedistribuidorService,
    private route: ActivatedRoute,
    private router: Router,
    private ofertaService: OfertaService) { }

  ngOnInit() {
    if (this.route.snapshot.params.idoferta) {
      this.ofertaService.findById(this.route.snapshot.params.idoferta).subscribe(ofer => {
        this.oferta = <Oferta>ofer;
      });
    }

    this.oferta.flpagseguro = false;

    this.getFabricanteList();
  }

  getFabricanteList() {
    this.fabricanteDistribuidorService.findAll().subscribe(data => {
      this.fabricanteDistribuidorList = data;
    });
  }

  getProdutoList() {
    this.produtoService.findAll().subscribe(resposta => {
      this.produtosList = resposta;
      this.produtosList = this.produtosList.filter((element) =>
        element.idfabricantedistribuidor == this.produto.idfabricantedistribuidor
      );
    });
  }

  adicionarProduto(produto: Produto) {
    // this.produtosList = this.produtosList.filter((element) => element.idfabricantedistribuidor == this.produto.idfabricantedistribuidor);
    this.produtosAdicionados.push(produto);
  }

  removerProduto(produto: Produto) {
    this.produtosList = this.produtosList.filter((element) => element.idfabricantedistribuidor === this.produto.idfabricantedistribuidor);
    this.produtosAdicionados.splice(this.produtosAdicionados.indexOf(produto), 1);
  }

  gravarDados() {
    this.oferta.ofertaProduto = [];
    // Prepara os produtos a serem adicionados a oferta
    for (const produto of this.produtosAdicionados) {
      const ofertaProduto: OfertaProduto = new OfertaProduto();
      ofertaProduto.idproduto = produto.idproduto;
      this.oferta.ofertaProduto.push(ofertaProduto);
    }

    this.oferta.iddistribuidor = JSON.parse(sessionStorage.getItem('user')).iddistribuidor;

    this.ofertaService.save(this.oferta).subscribe(
      success => {
        Swal.fire('Sucesso', 'Dados cadastrados com sucesso!', 'success')
          .then(() => {
            this.router.navigate(['/', 'main', 'oferta']);
          });
      },
      error => {
        Swal.fire('Erro', 'Ocorreu um erro na gravação!\n' + JSON.stringify(error), 'error');
      }
    );
  }

  getImage() {
    let file: any = document.querySelector('#dsimagem');
    file = file.files[0];

    this.getBase64(file);
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.oferta.dsimagem = reader.result.toString();
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

}
