
<body class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <a><b>Controle</b>Licença</a>
    </div>
    <!-- /.login-logo -->
    <div class="login-box-body">
      <form #frmNovaSenha="ngForm">
        <p class="login-box-msg">Digite sua nova senha e confirmação para recuperar o acesso ao sistema.</p>
        <div class="form-group" [ngClass]="{'has-error': dssenha.invalid && (dssenha.dirty || dssenha.touched)}">
          <input type="password" [(ngModel)]="usuario.dssenha" #dssenha="ngModel" name="dssenha" class="form-control" required placeholder="Senha">
          <span class="help-block" *ngIf="dssenha.invalid && (dssenha.dirty || dssenha.touched)">
            Senha é obrigatório
          </span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': dsconfirmacao.invalid && (dsconfirmacao.dirty || dsconfirmacao.touched)}">
          <input type="password" [(ngModel)]="usuario.dsconfirmacao" #dsconfirmacao="ngModel" name="dsconfirmacao" class="form-control" required placeholder="Confirmar Senha">
          <span class="help-block" *ngIf="dsconfirmacao.invalid && (dsconfirmacao.dirty || dsconfirmacao.touched)">
            Confirmação de senha é obrigatório
          </span>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" (click)="reenviarSenha()" [disabled]="frmNovaSenha.invalid" class="btn btn-primary float-right">ENVIAR</button>
          </div>
          <div class="col-6">
            <button type="button" [routerLink]="[ '/login' ]" class="btn btn-secondary">VOLTAR</button>
          </div>
          <!-- /.col -->
        </div>
      </form>
    </div>
    <!-- /.login-box-body -->
  </div>
  <!-- /.login-box -->

</body>
