import { Component, OnInit } from '@angular/core';
import { Produto } from 'src/app/model/Produto';
import { ProdutoService } from 'src/app/service/Produto.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatatableCustomConfigService } from 'src/app/shared/datatable-custom-config.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.css']
})
export class ProdutoComponent implements OnInit {

  produto: Produto = new Produto();
  produtosList: Produto[];
  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  datatableCustomConfigService = new DatatableCustomConfigService();

  constructor(
    private produtoService: ProdutoService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb('Produto', 'Cadastro de produtos');
    this.dtOptions = this.datatableCustomConfigService.options;
    this.listaProdutos();
  }

  listaProdutos() {
    this.produtoService.findAll().pipe(take(1)).subscribe(data =>{
      this.produtosList = data;
      this.dtTrigger.next(this.dtOptions);
    });
  }

  editar(produto: Produto) {
    this.produto = produto;
    const element = document.getElementById('nmproduto');
    element.focus();
  }

  excluir(produto: Produto) {
    this.produtoService.delete(produto.idproduto).subscribe(
        data => {
          Swal.fire('', 'PRODUTO EXCLUÍDO COM SUCESSO', 'success');
          this.listaProdutos();
        }
      , error => Swal.fire('', 'ERRO AO EXCLUIR O PRODUTO', 'error')
    );
  }


}
