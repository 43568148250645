import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/Usuario.service';
import { Usuario } from 'src/app/model/Usuario';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-revenda-cadastro',
  templateUrl: './usuario-revenda-cadastro.component.html',
  styleUrls: ['./usuario-revenda-cadastro.component.css']
})
export class UsuarioRevendaCadastroComponent implements OnInit {

  usuario: Usuario = new Usuario();
  confirmasenha = '';
  idRevenda: number;

  cpf = '0{3}.0{3}.0{3}-0{2} ';

  telefoneMask() {
    return '(9{2}) 9{5}-9{4}';
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.usuario.idrevendedor = this.idRevenda = this.route.snapshot.params.idrevendedor;
    this.usuario.idperfil = 2;
  }

  gravar() {
    this.usuarioService.save(this.usuario).subscribe(
      (res: any) => {
        if (res.success === true) {
          Swal.fire('Sucesso', 'Dados gravados com sucesso.', 'success')
            .then(() => {
              this.router.navigate([res.data.idusuario]);
            });
        } else {
          Swal.fire('Erro', res.message, 'error');
        }
      },
      (erro) => {
        Swal.fire('Erro', 'Erro ao tentar gravar os dados', 'error');
      });
  }
}
