import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Licenca } from '../model/Licenca';
import { CustomerProduct } from '../model/CustomerProduct';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  cancelar(licenca: Licenca): Observable<any> {
    const dados = {idrevendedor: licenca.idrevendedor, idtransacao: licenca.ativacao?.idtransacao, cdlicenca: licenca.cdlicenca, idlicenca: licenca.idlicenca, idpessoa: licenca.ativacao.idpessoa | licenca.idpessoa };
    return this.http.post(`${environment.apiUrl}/general/cancelar`, dados);
  }

  ativar(customerProduct: CustomerProduct): Observable<any> {
    return this.http.post(`${environment.apiUrl}/general/ativar`, customerProduct);
  }

  getCertificado(certificateCode: string){
    window.open(`${environment.apiUrl}/general/get-certificado/${certificateCode}`, '_blank');
  }
}
