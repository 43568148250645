import { Component, OnInit, Input } from '@angular/core';
import { Menu } from 'src/app/model/menu';
import { MenuService } from 'src/app/service/menu.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu-cadastro',
  templateUrl: './menu-cadastro.component.html',
  styleUrls: ['./menu-cadastro.component.css']
})
export class MenuCadastroComponent implements OnInit {

  @Input()
  menu: Menu = new Menu();
  @Input()
  buttons: boolean = true;
  @Input()
  onlyFields: boolean = false;

  frmMenu: any;

  constructor(private menuService: MenuService,
    private router: Router,
    private readonly fb: FormBuilder,
    private activatedRoute: ActivatedRoute) {

    this.frmMenu = this.fb.group({
      idmenu: [this.menu.idmenu, []],
      dsmenu: [this.menu.dsmenu, [Validators.required]],
      flstatus: [this.menu.flstatus, [Validators.required]],
      dsiconclass: [this.menu.dsiconclass, []],
      dslink: [this.menu.dslink, []],
      idmenupai: [this.menu.idmenupai, []],
      dtalteracao: [this.menu.dtalteracao || new Date(), []],
      dtcriacao: [this.menu.dtcriacao || new Date(), []],
    })

  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params['idmenu']) {
      this.menuService.findById(this.activatedRoute.snapshot.params['idmenu']).pipe(take(1))
        .subscribe({
          next: (res: any) => this.frmMenu.patchValue(res.data)
        });
    }
  }

  onSubmit() {
    var menu = Object.assign(this.menu, this.frmMenu.value);
    menu.dtcriacao = menu.dtcriacao ? new Date() : menu.dtcriacao;
    menu.dtalteracao = new Date();

    this.menuService.save(menu)
      .pipe(take(1))
      .subscribe({
        next: (data: any) => Swal.fire('', 'CADASTRADO REALIZADO COM SUCESSO', 'success').then(success => this.router.navigate(['/', 'main', 'menu', 'listar'])),
        error: (error: any) => console.log('error >> ', error)
      });
  }


}
