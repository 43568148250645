import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/service/Usuario.service';
import { Usuario } from 'src/app/model/Usuario';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-revenda',
  templateUrl: './usuario-revenda.component.html',
  styleUrls: ['./usuario-revenda.component.css']
})
export class UsuarioRevendaComponent implements OnInit {

  usuarioRevendedorList: Array<Usuario> = [];

  constructor(private route: ActivatedRoute,
    private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    if (this.route.snapshot.params.idrevendedor) {
      this.usuarioService.findByIdRevendedor(this.route.snapshot.params.idrevendedor)
        .subscribe((res: any) => {
          this.usuarioRevendedorList = res.data;
        });
    }
  }

  inativar(usuario: Usuario) {
    usuario.dtfim = new Date();
    this.usuarioService.udpate(usuario).subscribe((res: any) => {
      if (res.success) {
        Swal.fire('Sucesso', 'Dados gravados com sucesso', 'success');
      } else {
        Swal.fire('Erro', res.message, 'error');
      }
    });
  }

  reativar(usuario: Usuario) {
    usuario.dtfim = undefined;
    this.usuarioService.udpate(usuario).subscribe((res: any) => {
      if (res.success) {
        Swal.fire('Sucesso', 'Dados gravados com sucesso', 'success');
      } else {
        Swal.fire('Erro', res.message, 'error');
      }
    });
  }
}
