import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Revendedor } from 'src/app/model/Revendedor';
import { Observable, of } from 'rxjs';
import { Usuario } from '../model/Usuario';
import { UsuarioCytomic } from '../model/UsuarioCytomic';

@Injectable({
  providedIn: 'root'
})
export class RevendedorCytomicService {
  
  constructor(private http: HttpClient) { }

  findAll(): Observable<Revendedor[]> {
    return this.http.get<Revendedor[]>(`${environment.apiUrl}/view/revendedor-cytomic`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/revendedor-cytomic/${id}`);
  }

  findByIdExample(id: number) { }

  save(revendedor: Revendedor) {
    return this.http.post(`${environment.apiUrl}/view/revendedor-cytomic`, revendedor);
  }

  saveCytomic(revendedor: Revendedor) {
    return this.http.post(`${environment.apiUrl}/view/revendedor-cytomic-cytomic`, revendedor);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/revendedor-cytomic/${id}`);
  }

  saveUser(usuarioCytomic: UsuarioCytomic) {
    return this.http.post(`${environment.apiUrl}/view/revendedor-cytomic/user`, usuarioCytomic);
  }

  deleteUser(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/revendedor-cytomic/user/${id}`);
  }
}
