<div class="row">
    <div class="col-md-12">

        <div class="box">
            <div class="box-header with-border">
                <h3 class="box-title">Cadastro Seguro Cyber</h3>
            </div>
            <div class="box-body">
                <div class="row form-group">
                    <div class="col-md-4">
                        <label>Revendedor</label>
                        <select class="form-control select2" [(ngModel)]="lote.idrevendedor" required
                            name="idrevendedor" style="width: 100%;" tabindex="-1"
                            aria-hidden="true">
                            <option *ngFor="let rev of revendedorList" [ngValue]="rev.idrevendedor">{{rev.nmrevendedor}}
                            </option>
                        </select>
                    </div>
                    
                    <div class="col-md-4">
                        <label>Produto</label>
                        <select class="form-control select2" [(ngModel)]="lote.idproduto" #idproduto="ngModel" required
                            name="idproduto"
                            [ngClass]="{'has-error': idproduto.invalid && (idproduto.dirty || idproduto.touched)}"
                            style="width: 100%;" tabindex="-1" aria-hidden="true">
                            <option *ngFor="let prod of produtoList" [ngValue]="prod.idproduto">{{prod.nmproduto}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label>Início Vigência Contrato</label>
                        <input type="date" class="form-control" [(ngModel)]="customerProduct.dtinicioativacao"
                            name="dtinicioativacao" #dtinicioativacao="ngModel" placeholder="">
                    </div>

                    <div class="col-md-2">
                      <label>Fim Vigência Contrato</label>
                      <input type="date" class="form-control" [(ngModel)]="customerProduct.dtfimativacao"
                          name="dtfimativacao" #dtfimativacao="ngModel" placeholder="">
                  </div>
                    
                </div>

                <div class="row form-group">
                    <div class="col-md-12">
                        <label>Razão Social/Nome</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dssobrenome"
                            name="dssobrenome" #dssobrenome="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>CNPJ/CPF</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dscpfcnpj" name="dscpfcnpj"
                            mask="CPF_CNPJ" #dscpfcnpj="ngModel">
                    </div>
                    <div class="col-md-3">
                        <label>E-mail</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dsemail" name="dsemail"
                            #dsemail="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>Telefone</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dstelefone" name="dstelefone"
                            #dstelefone="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>Data Abertura</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dtnascimento"
                            name="dtnascimento" mask="00/00/0000" #dtnascimento="ngModel">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-2">
                        <label>CEP</label>
                        <input mask="00000-000" [(ngModel)]="customerProduct.dscep" name="dscep" #dscep="ngModel"
                            (blur)="buscaCep()" type="tel" class="form-control" />
                        <!-- <input type="text" class="form-control" [(ngModel)]="customerProduct.dscep" name="dscep" #dscep="ngModel" placeholder=""> -->
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-6">
                        <label>Logradouro</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dslogradouro"
                            name="dslogradouro" #dslogradouro="ngModel" placeholder="">
                    </div>
                    <div class="col-md-2">
                        <label>Número</label>
                        <input type="text" id="nbendereco" class="form-control" [(ngModel)]="customerProduct.nbendereco"
                            name="nbendereco" #nbendereco="ngModel" placeholder="">
                    </div>
                    <div class="col-md-4">
                        <label>Complemento</label>
                        <input type="text" id="dscomplemento" class="form-control"
                            [(ngModel)]="customerProduct.dscomplemento" name="dscomplemento" #dscomplemento="ngModel"
                            placeholder="">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-3">
                        <label>Bairro</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dsbairro" name="dsbairro"
                            #dsbairro="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>Cidade</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dscidade" name="dscidade"
                            #dscidade="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>Estado</label>
                        <input type="text" class="form-control" [(ngModel)]="customerProduct.dsuf" name="dsuf"
                            #dsuf="ngModel" placeholder="">
                    </div>
                    <div class="col-md-3">
                        <label>País</label>
                        <select class="form-control select2" [(ngModel)]="customerProduct.idpais" name="idpais"
                            #idpais="ngModel" style="width: 100%;" aria-hidden="true">
                            <option value="1" selected="selected">Brasil</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-11">
        <button type="submit" (click)="gravarDados()" class="btn btn-primary pull-right">Cadastrar</button>
    </div>
    <div class="col-md-1">
        <button type="button" [routerLink]="[ '/', 'main', 'revenda' ]" class="btn btn-default">Voltar</button>
    </div>
</div>