import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Revendedoroferta } from 'src/app/model/Revendedoroferta';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevendedorofertaService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Revendedoroferta[]> {
    return this.http.get<Revendedoroferta[]>(`${environment.apiUrl}/view/revendedoroferta`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/revendedoroferta/${id}`);
  }

  findByIdRevendedor(revendedorId: number) {
    return this.http.get(`${environment.apiUrl}/view/revendedoroferta/revendedor/${revendedorId}`);
  }

  save(revendedoroferta: Revendedoroferta) {
    return this.http.post(`${environment.apiUrl}/view/revendedoroferta`, revendedoroferta);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/revendedoroferta/${id}`);
  }
}
