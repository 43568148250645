<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-header">
        <div class="row">
          <div class="col-md-11">
            <h4 class="box-title">Aqui poderá ser realizada a consulta, edição, exclusão e adicionar um novo produto.
            </h4>
          </div>
          <div class="col-md-1">
            <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
          </div>
        </div>
      </div>
      <div class="box-body table-responsive">
        <div class="row">
          <div class="col-12">
            <table id="example1" class="table table-striped table-bordered" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
              <thead>
                <tr>
                  <th><input type="checkbox"></th>
                  <th>#</th>
                  <th>Descrição</th>
                  <th>Fabricante</th>
                  <th>MPN</th>
                  <th>NCM</th>
                  <th>Preço Custo</th>
                  <th>Preço Venda</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let prod of produtosList">
                  <td><input type="checkbox" value="{{prod.idproduto}}"></td>
                  <td>{{prod.idproduto}}</td>
                  <td>{{prod.nmproduto}}</td>
                  <td>{{prod.fabricanteDistribuidor.fabricante.nmfabricante}}</td>
                  <td>{{prod.cdproduto}}</td>
                  <td>{{prod.nbncm}}</td>
                  <td>{{prod.vlcusto | currency:'BRL'}}</td>
                  <td>{{prod.vlvenda | currency:'BRL'}}</td>
                  <td>
                    <a class="btn btn-sm btn-primary" title="Editar" [routerLink]="[ 'editar', prod.idproduto ]"><span
                        class="fa fa-pencil"></span></a>&nbsp;
                    <a class="btn btn-sm btn-danger" title="Excluir" (click)="excluir(prod)"><span
                        class="fa fa-trash"></span></a>
                  </td>
                </tr>
            </table>
          </div>
        </div>
        
      </div>
    </div>
  </div>