<div class="box">
    <div class="box-header text-bold">
        Cadastro de Menu
    </div>
    <form [formGroup]="frmMenu" (ngSubmit)="onSubmit()" novalidate>
        <!-- /.box-header -->
        <div class="box-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label for="dsmenu">Descrição</label>
                    <input type="text" name="dsmenu" id="dsmenu" formControlName="dsmenu" class="form-control"
                        [ngClass]="{'is-invalid': frmMenu.controls.dsmenu.invalid && (frmMenu.controls.dsmenu.dirty || frmMenu.controls.dsmenu.touched)}"
                        required>
                    <div *ngIf="frmMenu.controls.dsmenu.errors?.['required']  && (frmMenu.controls.dsmenu.dirty || frmMenu.controls.dsmenu.touched)"
                        class="text-danger">
                        dsmenu é obrigatório.
                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="flstatus">Ativo?</label>
                    <font size="5">
                        <span class="fa" id="flstatus" name="flstatus"
                            (click)="frmMenu.value.flstatus = !frmMenu.value.flstatus"
                            [class]="{'fa-toggle-on': frmMenu.value.flstatus, 'fa-toggle-off': !frmMenu.value.flstatus}"></span>
                    </font>
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsiconclass">Classe CSS</label>
                    <input type="text" name="dsiconclass" id="dsiconclass" formControlName="dsiconclass"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dslink">Link</label>
                    <input type="text" name="dslink" id="dslink" formControlName="dslink" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="idmenupai">Cód. Menu Pai</label>
                    <input type="text" name="idmenupai" id="idmenupai" formControlName="idmenupai" class="form-control">
                </div>
            </div>
        </div>
        <div class="box-footer" *ngIf="buttons">
            <div class="row">
                <div class="col-6 text-right">
                    <a onclick="window.history.go(-1);" class="btn btn-secondary">VOLTAR</a>
                </div>
                <div class="col-6">
                    <button type="submit" class="btn btn-primary">GRAVAR</button>
                </div>
            </div>
        </div>
    </form>
</div>