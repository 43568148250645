import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { Perfil } from 'src/app/model/perfil';
import { PerfilService } from 'src/app/service/perfil.service';
import { DatatableCustomConfigService } from 'src/app/shared/datatable-custom-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil-listar',
  templateUrl: './perfil-listar.component.html',
  styleUrls: ['./perfil-listar.component.css']
})
export class PerfilListarComponent implements OnInit {

  perfil: Perfil = new Perfil();
  perfilList: Perfil[] = [];
  dtOptions!: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  datatableCustomConfigService = new DatatableCustomConfigService();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  constructor(private perfilService: PerfilService) { }

  ngOnInit() {
    this.listaPerfil();
    this.dtOptions = this.datatableCustomConfigService.options;
  }

  listaPerfil() {
    this.perfilService.findAll().pipe(take(1)).subscribe({
      next: (res: any) => {
        this.perfilList = res.data;
        if (this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then(a => a.destroy());
        }
        this.dtTrigger.next(this.dtOptions);
      },
      error: (error: any) => {
        Swal.fire('', 'Erro ao Consultar Perfil', 'error');
        console.log('erro consulta perfil', error);
      }
    });
  }

  excluir(perfil: Perfil) {
    this.perfilService.delete(perfil.idperfil).pipe(take(1))
      .subscribe({
        next: (data: any) => Swal.fire('', 'REGISTRO EXCLU\u00CDDO COM SUCESSO', 'success').then(success => this.listaPerfil()),
        error: (error: any) => Swal.fire('', 'ERRO AO EXCLUIR O REGISTRO', 'error')
      });
  }
}
