<div class="row">
   <!--  <div class="col-md-12">
        <h3>Ativações por produto</h3>
    </div> -->
    <div class="col-md-6" *ngFor="let block of arrBlocks" (click)="getDetails(block[0])">

        <div class="info-box" [ngClass]="{'bg-red': block[2] <= 0, 'bg-green': block[2] > 0}" style="cursor:pointer;">
            <span class="info-box-icon"><i class="ion ion-ios-cloud-download-outline"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">{{ block[1] }}</span>
              <span class="info-box-number">{{ block[2] }}</span>

              <div class="progress">
                <div class="progress-bar" style="width: 70%"></div>
              </div>
              <!-- <span class="progress-description">
                    70% Increase in 30 Days
                  </span> -->
            </div>
            <!-- /.info-box-content -->
          </div>

       
    </div>
</div>