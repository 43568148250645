import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Endereco } from 'src/app/model/Endereco';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(private http: HttpClient) { }

  find(cep: string) {
      return this.http.get(`https://servicos.inocon.com.br:8443/rest/wsCep/endereco/cep/${cep}/logradouro/_/`);
   }

}
