<div class="box">
    <div class="box-header with-border">
        <h3 class="box-title">Informações principais</h3>
    </div>
    <div class="box-body">
        <div class="row">
            <div class="col-md-12">
                <div class="row form-group">
                    <div class="col-md-12">
                        <label>Nome do fabricante</label>
                        <input type="text" readonly class="form-control" [(ngModel)]="fabricante.nmfabricante" placeholder="">
                    </div>
                    <div class="col-md-12">
                        <label>Nome do Distribuidor</label>
                        <select class="form-control" [(ngModel)]="fabricantedistribuidor.iddistribuidor" id="iddistribuidorList">
                            <option *ngFor="let distribuidor of distribuidorList" [value]="distribuidor.iddistribuidor">
                                {{distribuidor.nmdistribuidor}}</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">
                        <label>Data início</label>
                        <input type="date" class="form-control" [(ngModel)]="fabricantedistribuidor.dtinicio" placeholder="">
                    </div>
                    <div class="col-md-4">
                        <label>Data fim</label>
                        <input type="date" class="form-control" [(ngModel)]="fabricantedistribuidor.dtfim" placeholder="">
                    </div>
                </div>
            </div>
            <div class="box-footer col-md-12">
                <button type="submit" class="btn btn-primary pull-right" (click)="gravar()">GRAVAR</button>&nbsp;
                <button type="button" class="btn btn-secondary pull-right"
                    [routerLink]="['/','main','fabricante']">VOLTAR</button>&nbsp;
            </div>
        </div>
    </div>

    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Lista de distribuidores para este fabricante</h3>
        </div>
        <div class="box-body">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>ID Distribuidor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fabDist of fabricanteDistribuidorList">
                        <td>{{fabDist.distribuidor.nmdistribuidor}}</td>
                        <td>
                            <button class="btn btn-sm btn-danger" title="Excluir" (click)="excluir(fab)"><span
                                    class="fa fa-trash"></span></button>&nbsp;
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                </tfoot>
            </table>
        </div>
    </div>
</div>