<div class="box">
  <div class="box-body">
    <div class="row">
      <div class="col-md-11">
        <h4 class="box-title">Aqui poderá ser realizada a consulta, edição, exclusão e adicionar Pessoa.
        </h4>
      </div>
      <div class="col-md-1">
        <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
      </div>
    </div>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
   sfadsfasdfasdfadsf
    <div class="table-responsive">
      <table id="pessoaListagem" class="table table-bordered table-striped" datatable [dtTrigger]="dtTrigger"
        [dtOptions]="dtOptions">
        <thead>
          <tr>
            <!-- <th><input type="checkbox"></th> -->
            <th>idpessoa</th>
            <th>nmpessoa</th>
            <th>dssobrenome</th>
            <th>dslogradouro</th>
            <th>nbendereco</th>
            <th>dsemail</th>
            <th>dstelefone</th>
            <th>dscpfcnpj</th>
            <th>dtcriacao</th>
            <th>dtalteracao</th>
            <th>&nbsp;</th>
        </thead>
        <tbody>
          <tr *ngFor="let res of pessoaList">
            <!-- <th><input type="checkbox"></th> -->
            <td>{{ res.idpessoa }}</td>
            <td>{{ res.nmpessoa }}</td>
            <td>{{ res.dssobrenome }}</td>
            <td>{{ res.dslogradouro }}</td>
            <td>{{ res.nbendereco }}</td>
            <td>{{ res.dsemail }}</td>
            <td>{{ res.dstelefone }}</td>
            <td>{{ res.dscpfcnpj }}</td>
            <td>{{ res.dtcriacao | date: 'dd/MM/yyyy hh:mm'}}</td>
            <td>{{ res.dtalteracao | date: 'dd/MM/yyyy hh:mm'}}</td>
            <td>
              <a class="btn btn-sm btn-primary" title="Editar" [routerLink]="['editar', res.idPessoa ]"><span
                  class="fa fa-pencil"></span></a>&nbsp;
              <a class="btn btn-sm btn-danger" title="Excluir" (click)="excluir(res)"><span
                  class="fa fa-trash"></span></a>&nbsp;
            </td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<!-- /.box-body -->



<!-- /.row -->