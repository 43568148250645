import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Usuario } from 'src/app/model/Usuario';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  constructor(private http: HttpClient) { }

  findAll(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${environment.apiUrl}/view/usuario`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/usuario/${id}`);
  }

  findByIdExample(id: number) { }

  save(usuario: Usuario) {
    return this.http.post(`${environment.apiUrl}/view/usuario`, usuario);
  }

  udpate(usuario: Usuario) {
    return this.http.put(`${environment.apiUrl}/view/usuario`, usuario);
  }

  login(usuario: Usuario) {
    return this.http.post(`${environment.apiUrl}/view/usuario/login`, usuario);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/usuario/${id}`);
  }

  findByIdRevendedor(idrevendedor) {
    return this.http.get(`${environment.apiUrl}/view/usuario/revenda/${idrevendedor}`);
  }

  recovery(usuario: Usuario) {
    return this.http.post(`${environment.apiUrl}/view/usuario/recovery`, usuario);
  }

  changePassword(usuario: Usuario) {
    return this.http.post(`${environment.apiUrl}/view/usuario/change-password`, usuario);
  }

}
