import { Revendedor } from './Revendedor';
import { Produto } from './Produto';
import { Oferta } from './Oferta';

export class Lote {
    idlote: number;
    idrevendedor: number;
    dtpedido: Date;
    dtinicio: Date;
    dtfim: Date;
    idproduto: number;
    nbquantidadelicencas: number;
    idoferta: number;
    fllicenciado: boolean;
    totalAtivos: number;
    dtcriacao: Date;
    dtalteracao: Date;
    dtexpiracao: Date;

    revendedor: Revendedor;
    produto: Produto;
    oferta: Oferta;
}
