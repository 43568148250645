<div class="box">
    <div class="box-header text-bold">
        Cadastro de Pessoa
    </div>
    <form [formGroup]="frmPessoa" (ngSubmit)="onSubmit()" novalidate>
        <!-- /.box-header -->
        <div class="box-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label for="nmpessoa">nmpessoa</label>
                    <input type="text" name="nmpessoa" id="nmpessoa" formControlName="nmpessoa" class="form-control"
                        [ngClass]="{'is-invalid': frmPessoa.controls.nmpessoa.invalid && (frmPessoa.controls.nmpessoa.dirty || frmPessoa.controls.nmpessoa.touched)}"
                        required>
                    <div *ngIf="frmPessoa.controls.nmpessoa.errors?.['required']  && (frmPessoa.controls.nmpessoa.dirty || frmPessoa.controls.nmpessoa.touched)"
                        class="text-danger">
                        nmpessoa é obrigatório.

                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="dssobrenome">dssobrenome</label>
                    <input type="text" name="dssobrenome" id="dssobrenome" formControlName="dssobrenome"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dslogradouro">dslogradouro</label>
                    <input type="text" name="dslogradouro" id="dslogradouro" formControlName="dslogradouro"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="nbendereco">nbendereco</label>
                    <input type="text" name="nbendereco" id="nbendereco" formControlName="nbendereco"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsbairro">dsbairro</label>
                    <input type="text" name="dsbairro" id="dsbairro" formControlName="dsbairro" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dscidade">dscidade</label>
                    <input type="text" name="dscidade" id="dscidade" formControlName="dscidade" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dscep">dscep</label>
                    <input type="text" name="dscep" id="dscep" formControlName="dscep" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsuf">dsuf</label>
                    <input type="text" name="dsuf" id="dsuf" formControlName="dsuf" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsemail">dsemail</label>
                    <input type="text" name="dsemail" id="dsemail" formControlName="dsemail" class="form-control"
                        [ngClass]="{'is-invalid': frmPessoa.controls.dsemail.invalid && (frmPessoa.controls.dsemail.dirty || frmPessoa.controls.dsemail.touched)}"
                        required>
                    <div *ngIf="frmPessoa.controls.dsemail.errors?.['required']  && (frmPessoa.controls.dsemail.dirty || frmPessoa.controls.dsemail.touched)"
                        class="text-danger">
                        dsemail é obrigatório.

                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="dstelefone">dstelefone</label>
                    <input type="text" name="dstelefone" id="dstelefone" formControlName="dstelefone"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsobservacao">dsobservacao</label>
                    <input type="text" name="dsobservacao" id="dsobservacao" formControlName="dsobservacao"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="idpais">idpais</label>
                    <input type="text" name="idpais" id="idpais" formControlName="idpais" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dscomplemento">dscomplemento</label>
                    <input type="text" name="dscomplemento" id="dscomplemento" formControlName="dscomplemento"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dstelefonecomercial">dstelefonecomercial</label>
                    <input type="text" name="dstelefonecomercial" id="dstelefonecomercial"
                        formControlName="dstelefonecomercial" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dsramal">dsramal</label>
                    <input type="text" name="dsramal" id="dsramal" formControlName="dsramal" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dscpfcnpj">dscpfcnpj</label>
                    <input type="text" name="dscpfcnpj" id="dscpfcnpj" formControlName="dscpfcnpj" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dtcriacao">dtcriacao</label>
                    <input type="text" name="dtcriacao" id="dtcriacao" formControlName="dtcriacao" class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dtnascimento">dtnascimento</label>
                    <input type="text" name="dtnascimento" id="dtnascimento" formControlName="dtnascimento"
                        class="form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label for="dtalteracao">dtalteracao</label>
                    <input type="text" name="dtalteracao" id="dtalteracao" formControlName="dtalteracao"
                        class="form-control"
                        [ngClass]="{'is-invalid': frmPessoa.controls.dtalteracao.invalid && (frmPessoa.controls.dtalteracao.dirty || frmPessoa.controls.dtalteracao.touched)}"
                        required>
                    <div *ngIf="frmPessoa.controls.dtalteracao.errors?.['required']  && (frmPessoa.controls.dtalteracao.dirty || frmPessoa.controls.dtalteracao.touched)"
                        class="text-danger">
                        dtalteracao é obrigatório.

                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="cdparceiro">cdparceiro</label>
                    <input type="text" name="cdparceiro" id="cdparceiro" formControlName="cdparceiro"
                        class="form-control">
                </div>
            </div>
        </div>
        <div class="box-footer" *ngIf="buttons">
            <div class="row">
                <div class="col-6 text-right">
                    <a onclick="window.history.go(-1);" class="btn btn-secondary">VOLTAR</a>
                </div>
                <div class="col-6">
                    <button type="submit" class="btn btn-primary" [disabled]="frmPessoa.invalid">GRAVAR</button>
                </div>
            </div>
        </div>
    </form>
</div>