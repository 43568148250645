import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { Menu } from 'src/app/model/menu';
import { MenuService } from 'src/app/service/menu.service';
import { DatatableCustomConfigService } from 'src/app/shared/datatable-custom-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu-listar',
  templateUrl: './menu-listar.component.html',
  styleUrls: ['./menu-listar.component.css']
})
export class MenuListarComponent implements OnInit {

  menu: Menu = new Menu();
  menuList: Menu[] = [];
  dtOptions!: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  datatableCustomConfigService = new DatatableCustomConfigService();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  constructor(private menuService: MenuService) { }

  ngOnInit() {
    this.listaMenu();
    this.dtOptions = this.datatableCustomConfigService.options;
  }

  listaMenu() {
    this.menuService.findAll().pipe(take(1)).subscribe({
      next: (res: any) => {
        this.menuList = res.data;
        if(this.dtElement.dtInstance){
          this.dtElement.dtInstance.then(a => a.destroy());
        }
        this.dtTrigger.next(this.dtOptions);
      },
      error: (error: any) => {
            Swal.fire('', 'Erro ao Consultar Menu', 'error');
            console.log('erro consulta menu', error);
            }
    });
  }

  excluir(menu: Menu) {
    this.menuService.delete(menu.idmenu).pipe(take(1))
    .subscribe({
      next: (data: any) => Swal.fire('', 'REGISTRO EXCLU\u00CDDO COM SUCESSO', 'success').then(success => this.listaMenu() ),
      error: (error: any) => Swal.fire('', 'ERRO AO EXCLUIR O REGISTRO', 'error')
    });
  }
}
