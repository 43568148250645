import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Revendedorproduto } from 'src/app/model/Revendedorproduto';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevendedorprodutoService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Revendedorproduto[]> {
    return this.http.get<Revendedorproduto[]>(`${environment.apiUrl}/view/revendedorproduto`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/revendedorproduto/${id}`);
  }

  findByIdRevendedor(revendedorId: number) { 
    return this.http.get(`${environment.apiUrl}/view/revendedorproduto/revendedor/${revendedorId}`);
  }

  save(revendedorproduto: Revendedorproduto) {
    return this.http.post(`${environment.apiUrl}/view/revendedorproduto`, revendedorproduto);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/revendedorproduto/${id}`);
  }
}
