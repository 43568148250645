import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Distribuidor } from 'src/app/model/Distribuidor';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DistribuidorService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Distribuidor[]> {
    return this.http.get<Distribuidor[]>(`${environment.apiUrl}/view/distribuidor`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/distribuidor/${id}`);
  }

  findByIdExample(id: number) { }

  save(distribuidor: Distribuidor) {
    return this.http.post(`${environment.apiUrl}/view/distribuidor`, distribuidor);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/distribuidor/${id}`);
  }
}
