<header class="main-header">
  <!-- Logo -->
  <a href="index2.html" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">{{usuario.idrevendedor ? usuario.revenda.nmrevendedor.substr(0,3) : usuario.distribuidor.nmdistribuidor.substr(0,3)}}</span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg">{{usuario.idrevendedor ? usuario.revenda.nmrevendedor : usuario.distribuidor.nmdistribuidor}}</span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a (click)="setCollapse()" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        
        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img src="assets/dist/img/user2-160x160.jpg" class="user-image" alt="User Image">
            <span class="hidden-xs">{{usuario.pessoa.nmpessoa | uppercase}}</span>
          </a>
          <ul class="dropdown-menu">
            <li class="user-header">
              <img src="assets/dist/img/user2-160x160.jpg" class="img-circle" alt="User Image">

              <p>
                {{usuario.pessoa.nmpessoa | uppercase}} {{usuario.pessoa.dssobrenome | uppercase}}
                <small>Cadastrado em: <br> {{usuario.dtinicio | date: 'dd/MM/yyyy'}}</small>
              </p>
            </li>
            <li class="user-footer">
              <div class="pull-left">
                <a href="" class="btn btn-default btn-flat">Perfil</a>
              </div>
              <div class="pull-right">
                <a href="javascript:void(0);" (click)="exit()" class="btn btn-default btn-flat">Sair</a>
              </div>
            </li>
          </ul>
        </li>
        <!-- <li>
          <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
        </li> -->
      </ul>
    </div>
  </nav>
</header>