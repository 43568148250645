import { Component, OnInit } from '@angular/core';
import { Revendedor } from 'src/app/model/Revendedor';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import Swal from 'sweetalert2';
import { Lote } from 'src/app/model/Lote';
import { OfertaService } from 'src/app/service/Oferta.service';
import { ProdutoService } from 'src/app/service/Produto.service';
import { Oferta } from 'src/app/model/Oferta';
import { Produto } from 'src/app/model/Produto';
import { LoteService } from 'src/app/service/Lote.service';
import { Router } from '@angular/router';
import { RevendedorprodutoService } from 'src/app/service/revendedorproduto.service';
import { RevendedorofertaService } from 'src/app/service/revendedoroferta.service';
import { Revendedorproduto } from 'src/app/model/Revendedorproduto';
import { Revendedoroferta } from 'src/app/model/Revendedoroferta';

@Component({
  selector: 'app-adicionar-token',
  templateUrl: './adicionar-token.component.html',
  styleUrls: ['./adicionar-token.component.css']
})
export class AdicionarTokenComponent implements OnInit {
  revendedorList: Revendedor[] = [];
  lote: Lote = new Lote();
  ofertaList: Oferta[] = [];
  produtoList: Produto[] = [];
  ofertaListTemp: Oferta[] = [];
  produtoListTemp: Produto[] = [];
  revendaProdutoList: Revendedorproduto[] = [];
  revendaOfertaList: Revendedoroferta[] = [];
  tipoToken: any;


  constructor(private revendedorService: RevendedorService,
              private produtoService: ProdutoService,
              private ofertaService: OfertaService,
              private loteService: LoteService,
              private revendaProdutoService: RevendedorprodutoService,
              private revendaOfertaService: RevendedorofertaService,
              private router: Router) { }

  ngOnInit() {
    this.getRevendedor();
    this.getProduto();
    this.getOferta();
  }

  getRevendedor() {
    this.revendedorService.findAll().subscribe(
      success => this.revendedorList = success,
      error => Swal.fire('Erro', 'Erro na busca de revendedores', 'error')
    );
  }

  getProduto() {
    this.produtoService.findAll().subscribe(
      success => this.produtoList = this.produtoListTemp = success,
      error => Swal.fire('Erro', 'Erro na busca de produtos', 'error')
    );
  }

  getOferta() {
    this.ofertaService.findAll().subscribe(
      success => this.ofertaList = this.ofertaListTemp = success,
      error => Swal.fire('Erro', 'Erro na busca de ofertas', 'error')
    );
  }

  getProdutoOferta() {
    this.revendaProdutoService.findByIdRevendedor(this.lote.idrevendedor)
    .subscribe((success: any) => {
      this.produtoList = this.produtoListTemp.filter(prod => success.some(i => i.idproduto === prod.idproduto) );
    });

    this.revendaOfertaService.findByIdRevendedor(this.lote.idrevendedor)
    .subscribe((success: any) => {
      this.ofertaList = this.ofertaListTemp.filter(afer => success.some(i => i.idoferta === afer.idoferta) );
      });
  }

  gravarDados() {
    this.loteService.gerarLote(this.lote).subscribe(
      (response: any) => {
        if(response.success == false){
          Swal.fire('Erro', response.message, 'error');
        }else{
          Swal.fire('Sucesso', 'Lote gerado com sucesso.', 'success');
          this.router.navigate(['/', 'main', 'token']);
        }
      },
      (error) => Swal.fire('Erro', 'Erro na geração do lote.', 'error')
    );
  }

}
