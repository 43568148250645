<div class="box">
  <div class="box-body">
    <div class="row">
      <div class="col-md-11">
        <h4 class="box-title">Aqui poderá ser realizada a consulta, edição, exclusão e adicionar Menu.
        </h4>
      </div>
      <div class="col-md-1">
        <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
      </div>
    </div>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
    <div class="table-responsive">
      <table id="menuList" class="table table-bordered table-striped" datatable [dtTrigger]="dtTrigger"
        [dtOptions]="dtOptions">
        <thead>
          <tr>
            <!-- <th><input type="checkbox"></th> -->
            <th>idmenu</th>
            <th>dsmenu</th>
            <th>flstatus</th>
            <th>dsiconclass</th>
            <th>dslink</th>
            <th>idmenupai</th>
            <th>dtalteracao</th>
            <th>dtcriacao</th>
            <th>&nbsp;</th>
        </thead>
        <tbody>
          <tr *ngFor="let res of menuList">
            <!-- <th><input type="checkbox"></th> -->
            <td>{{ res.idmenu }}</td>
            <td>{{ res.dsmenu }}</td>
            <td>{{ res.flstatus }}</td>
            <td>{{ res.dsiconclass }}</td>
            <td>{{ res.dslink }}</td>
            <td>{{ res.idmenupai }}</td>
            <td>{{ res.dtalteracao | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>{{ res.dtcriacao | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>
              <a class="btn btn-sm btn-primary" title="Editar" [routerLink]="[ 'editar', res.idmenu ]"><span
                  class="fa fa-pencil"></span></a>&nbsp;
              <a class="btn btn-sm btn-danger" title="Excluir" (click)="excluir(res)"><span
                  class="fa fa-trash"></span></a>&nbsp;
            </td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<!-- /.box-body -->



<!-- /.row -->