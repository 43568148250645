export class Revendedoroferta {
    idrevendedoroferta: number;
    idrevendedor: number;
    idoferta: number;
    flstatus: boolean;
    dtinicio: Date;
    dtfim: Date;
    dtcriacao: Date;
    dtalteracao: Date;
}
