import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Pessoa } from 'src/app/model/Pessoa';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PessoaService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Pessoa[]> {
    return this.http.get<Pessoa[]>(`${environment.apiUrl}/view/pessoa`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/pessoa/${id}`);
  }

  findByIdExample(id: number) { }

  save(pessoa: Pessoa) {
    return this.http.post(`${environment.apiUrl}/view/pessoa`, pessoa);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/pessoa/${id}`);
  }
}
