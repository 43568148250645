export class Fabricantedistribuidor {
    idfabricantedistribuidor: number;
    idfabricante: number;
    iddistribuidor: number;
    dtinicio: Date;
    dtfim: Date;
    dtcriacao: Date;
    dtalteracao: Date;
}

