<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Selecione o Revendedor, o Produto ou a Oferta</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-6">
            <label>Revendedor</label>
            <br>
            {{lote.revendedor?.nmrevendedor}}
          </div>
          <div class="col-md-6">
            <label>{{lote.idproduto ? 'Produto' : 'Oferta'}}</label>
            <br>
            {{lote.produto ? lote.produto?.nmproduto : lote.oferta?.nmoferta}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="box">
      <div class="box-header">
        <div class="box-title">
          <label> Informações do Token </label>
        </div>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-4">
            <label>Quantidade de Token ({{lote.idproduto ? 'Produto' : 'Oferta'}})</label>
            <br>
            {{lote.nbquantidadelicencas}}
          </div>
          <div class="col-md-2">
            <label>Licenciados</label><br>
            {{ lote.fllicenciado ? 'Sim' : 'Não' }}
          </div>
         
          <div class="col-md-2">
            <a class="btn btn-sm btn-primary" title="Baixar" label="Baixar" (click)="download()"><span
                class="fa fa-download"></span></a>&nbsp;
          </div>
        </div>
      </div>
      <!-- /.box-body -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-md-12">
        <a [routerLink]="[ '/','main', 'token' ]" class="btn btn-primary pull-right">Voltar</a>
      </div>
    </div>
  </div>
</div>