import { Component, OnInit, Input } from '@angular/core';
import { Perfil } from 'src/app/model/perfil';
import { PerfilService } from 'src/app/service/perfil.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil-cadastro',
  templateUrl: './perfil-cadastro.component.html',
  styleUrls: ['./perfil-cadastro.component.css']
})
export class PerfilCadastroComponent implements OnInit {

  @Input()
  perfil: Perfil = new Perfil();
  @Input()
  buttons: boolean = true;
  @Input()
  onlyFields: boolean = false;

  frmPerfil: any;

  constructor(private perfilService: PerfilService,
    private router: Router,
    private readonly fb: FormBuilder,
    private activatedRoute: ActivatedRoute) {

    this.frmPerfil = this.fb.group({
      idperfil: [this.perfil.idperfil, [Validators.required]],
      dsperfil: [this.perfil.dsperfil, [Validators.required]],
      flstatus: [this.perfil.flstatus, [Validators.required]],
      dtalteracao: [this.perfil.dtalteracao, []],
      dtcriacao: [this.perfil.dtcriacao, []],
    })

  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params['idperfil']) {
      this.perfilService.findById(this.activatedRoute.snapshot.params['idperfil']).pipe(take(1))
        .subscribe({
          next: (res: any) => this.frmPerfil.patchValue(res.data)
        });
    }
  }

  onSubmit() {
    var perfil = Object.assign(this.perfil, this.frmPerfil.value);
    perfil.dtcriacao = perfil.dtcriacao ? new Date() : perfil.dtcriacao;
    perfil.dtalteracao = new Date();
    
    this.perfilService.save(perfil)
      .pipe(take(1))
      .subscribe({
        next: (data: any) => Swal.fire('', 'CADASTRADO REALIZADO COM SUCESSO', 'success').then(success => this.router.navigate(['/', 'main', 'perfil', 'listar'])),
        error: (error: any) => console.log('error >> ', error)
      });
  }


}
