export class Fabricante {
    idfabricante: number;
    nmfabricante: string;
    dtinicio: Date;
    dtfim: Date;
    dtcriacao: Date;
    dtalteracao: Date;
    codigo: String
}

