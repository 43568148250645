import { Component, OnInit, Input } from '@angular/core';
import { Pessoa } from 'src/app/model/Pessoa';
import { PessoaService } from 'src/app/service/Pessoa.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pessoa-cadastro',
  templateUrl: './pessoa-cadastro.component.html',
  styleUrls: ['./pessoa-cadastro.component.css']
})
export class PessoaCadastroComponent implements OnInit {

  @Input()
  pessoa: Pessoa = new Pessoa();
  @Input()
  buttons: boolean = true;
  @Input()
  onlyFields: boolean = false;

  frmPessoa: any;

  constructor(private pessoaService: PessoaService,
    private router: Router,
    private readonly fb: FormBuilder,
    private activatedRoute: ActivatedRoute) {

    this.frmPessoa = this.fb.group({
      idpessoa: [this.pessoa.idpessoa, [Validators.required]],
      nmpessoa: [this.pessoa.nmpessoa, [Validators.required]],
      dssobrenome: [this.pessoa.dssobrenome, []],
      dslogradouro: [this.pessoa.dslogradouro, []],
      nbendereco: [this.pessoa.nbendereco, []],
      dsbairro: [this.pessoa.dsbairro, []],
      dscidade: [this.pessoa.dscidade, []],
      dscep: [this.pessoa.dscep, []],
      dsuf: [this.pessoa.dsuf, []],
      dsemail: [this.pessoa.dsemail, [Validators.required]],
      dstelefone: [this.pessoa.dstelefone, []],
      dsobservacao: [this.pessoa.dsobservacao, []],
      idpais: [this.pessoa.idpais, []],
      dscomplemento: [this.pessoa.dscomplemento, []],
      dstelefonecomercial: [this.pessoa.dstelefonecomercial, []],
      dsramal: [this.pessoa.dsramal, []],
      dscpfcnpj: [this.pessoa.dscpfcnpj, []],
      dtcriacao: [this.pessoa.dtcriacao, []],
      dtnascimento: [this.pessoa.dtnascimento, []],
      dtalteracao: [this.pessoa.dtalteracao, [Validators.required]],
    })

  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params['pessoa']) {
      this.pessoaService.findById(this.activatedRoute.snapshot.params['pessoa']).pipe(take(1))
        .subscribe({
          next: (res: any) => this.frmPessoa.patchValue(res.data)
        });
    }
  }

  onSubmit() {
    this.pessoaService.save(Object.assign(this.pessoa, this.frmPessoa.value))
      .pipe(take(1))
      .subscribe({
        next: (data: any) => Swal.fire('', 'CADASTRADO REALIZADO COM SUCESSO', 'success').then(success => this.router.navigate(['/', 'main', 'pessoa', 'listar'])),
        error: (error: any) => console.log('error >> ', error)
      });
  }


}
