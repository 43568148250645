import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/Produto.service';
import Swal from 'sweetalert2';
import { Produto } from 'src/app/model/Produto';
import { Router, ActivatedRoute } from '@angular/router';
import { FabricantedistribuidorService } from 'src/app/service/Fabricantedistribuidor.service';
import { Fabricantedistribuidor } from 'src/app/model/Fabricantedistribuidor';

@Component({
  selector: 'app-adicionar-produto',
  templateUrl: './adicionar-produto.component.html',
  styleUrls: ['./adicionar-produto.component.css']
})
export class AdicionarProdutoComponent implements OnInit {
  produto: Produto = new Produto();
  fabricanteDistribuidorList: Fabricantedistribuidor[];

  constructor(private fabricanteDistribuidorService: FabricantedistribuidorService,
    private produtoService: ProdutoService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.params.idproduto) {
      this.produtoService.findById(this.route.snapshot.params.idproduto).subscribe(prod => this.produto = <Produto>prod);
    }

    this.fabricanteDistribuidorService.findAll().subscribe(data => {
      this.fabricanteDistribuidorList = data;
      console.log(this.fabricanteDistribuidorList);
    });
  }

  gravar() {
    // console.log('produto', this.produto);
    this.produtoService.save(this.produto)
      .subscribe(data =>
                        Swal.fire('', 'PRODUTO CADASTRADO COM SUCESSO', 'success')
                          .then(success => this.router.navigate(['/', 'main', 'produto']),
                            error => console.log('erro >>', error)),
                error =>
                        Swal.fire('', 'ERRO AO PROCESSAR A SOLICITAÇÃO', 'error')
                          .then(success => console.log(error))
      );
  }

  getImage() {
    let file: any = document.querySelector('#dsimagem');
    file = file.files[0];

    this.getBase64(file);
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.produto.dsimagem = reader.result.toString();
      // console.log(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }


}
