<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-body">
        <div class="row">
          <div class="col-md-11">
            <h4 class="box-title">Aqui poderá ser feita a consulta, edição e exclusão de uma oferta.
            </h4>
          </div>
          <div class="col-md-1">
            <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
          </div>
        </div>
      </div>

      <!-- /.box-header -->
      <div class="box-body">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th><input type="checkbox" [(ngModel)]="todos"></th>
              <th>Cod da Oferta</th>
              <th>Nome da Oferta</th>
              <th>Preço de Custo</th>
              <th>Preço de Venda</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Recorrência</th>
              <th>Plano PagSeguro</th>
              <th>Ações</th>
          </thead>
          <tbody>
            <tr *ngFor="let ofr of ofertaList">
              <td><input type="checkbox" [checked]='todos==true'></td>
              <td>{{ofr.idoferta}}</td>
              <td>{{ofr.nmoferta}}</td>
              <td>{{ofr.vlcusto | currency:'BRL'}}</td>
              <td>{{ofr.vlvenda | currency:'BRL'}}</td>
              <td>{{ofr.dtinicio | date: 'dd/MM/yyyy'}}</td>
              <td>{{ofr.dtfim | date: 'dd/MM/yyyy'}}</td>
              <td>{{ofr.flpagseguro ? 'Sim' : 'Não'}}</td>
              <td>{{ofr.dspagseguroid}}</td>
              <td>
                <!-- <a href="" class="btn btn-sm btn-primary" title="Editar" label="Alterar" [routerLink]="['editar', ofr.idoferta ]"><span
                    class="fa fa-pencil"></span></a>&nbsp; -->
                <a (click)="excluir(ofr)" *ngIf="!ofr.dtfim" class="btn btn-sm btn-danger" title="Excluir" label="Ver"><span class="fa fa-trash"></span></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
  <!-- /.box-body -->
</div>


<!-- /.row -->