import { Component, OnInit } from '@angular/core';
import { Lote } from 'src/app/model/Lote';
import { Revendedor } from 'src/app/model/Revendedor';
import { LoteService } from 'src/app/service/Lote.service';
import { BreadcrumbService } from 'src/app/service/Breadcrumb.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {
  loteList: Lote[] = [];
  usuario = JSON.parse(sessionStorage.getItem('user'));
  isRevenda: Boolean = (this.usuario.idrevendedor && this.usuario.idrevendedor !== null);
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any[]> = new Subject();

  constructor(private loteService: LoteService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {

    if (this.isRevenda === true) {
      this.breadcrumbService.setBreadcrumb('Licenças', 'Lista das licenças geradas para sua revenda');
    } else {
      this.breadcrumbService.setBreadcrumb('Tokens', 'Cadastro de tokens');
    }

    this.getLote(7);
  }

  getLote(dias: number) {
    if (this.isRevenda) {
      this.loteService.findByIdRevenda(JSON.parse(sessionStorage.getItem('user')).idrevendedor).subscribe(
        success => this.loteList = success,
        error => console.log(error)
      );
    } else {
      this.loteService.findAll(dias).subscribe(
        success => this.loteList = success,
        error => console.log(error)
      );
    }
  }
}
