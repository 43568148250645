import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seguro-cyber',
  templateUrl: './seguro-cyber.component.html',
  styleUrls: ['./seguro-cyber.component.css']
})
export class SeguroCyberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
