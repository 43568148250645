export class Menu {
    idmenu!: number;
    dsmenu!: string;
    flstatus!: boolean;
    dsiconclass!: string;
    dslink!: string;
    idmenupai!: number;
    dtalteracao!: Date;
    dtcriacao!: Date;
}

