import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Usuario } from '../model/Usuario';
import { UsuarioService } from '../service/Usuario.service';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css']
})
export class NovaSenhaComponent implements OnInit {

  usuario: Usuario = new Usuario();
  dsRecoveryKey: string;

  constructor(private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private router: Router) { }

  ngOnInit(): void {
    if(this.activatedRoute.snapshot.params.dsrecoverykey){
      this.dsRecoveryKey = this.activatedRoute.snapshot.params.dsrecoverykey;
    }else{
      Swal.fire('Erro', 'Chave de recuperação não encontrada', 'error');
    }
  }

  reenviarSenha(){
    this.usuario.dsrecoverykey = this.dsRecoveryKey;
    this.usuarioService.changePassword(this.usuario).pipe(take(1))
    .subscribe({
      next: (res: any) => {
        if(res.success && res.success == false){
          Swal.fire('Erro', res.message, 'error').then(() => this.router.navigate(['/', 'login']));
        }else{
          Swal.fire('Sucesso', 'Senha alterada com sucesso!', 'success').then(() => this.router.navigate(['/', 'login']));
        }
      },
      error: (err) => Swal.fire('Erro', err.error.message, 'error')
    })
  }

}
